'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Page$NimbusWeb = require("../../components/Page.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Loader$NimbusWeb = require("../../components/Loader.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Section$NimbusWeb = require("../../components/Section.bs.js");
var TextField$NimbusWeb = require("../../components/TextField.bs.js");
var Navigation$NimbusWeb = require("../../utils/Navigation.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var DragAndDrop$NimbusWeb = require("../../components/DragAndDrop.bs.js");
var ModeContext$NimbusWeb = require("../../contexts/ModeContext.bs.js");
var ErrorContext$NimbusWeb = require("../../contexts/ErrorContext.bs.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var CheckListAdmin$NimbusWeb = require("../../components/CheckListAdmin.bs.js");
var OrganizationRequest_Create$NimbusWeb = require("./requests/OrganizationRequest_Create.bs.js");

require("./OrganizationCreate.css");

function OrganizationCreate(Props) {
  Props.path;
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match[0];
  var match$1 = React.useContext(ModeContext$NimbusWeb.context);
  var mode = match$1[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  var match$2 = React.useState((function () {
          return ;
        }));
  var setLogo = match$2[1];
  var logo = match$2[0];
  var match$3 = React.useState((function () {
          return ;
        }));
  var setLogoFile = match$3[1];
  var logoFile = match$3[0];
  var match$4 = React.useState((function () {
          return ;
        }));
  var setLogoHeader = match$4[1];
  var logoHeader = match$4[0];
  var match$5 = React.useState((function () {
          return ;
        }));
  var setLogoHeaderFile = match$5[1];
  var logoHeaderFile = match$5[0];
  var match$6 = React.useState((function () {
          return "";
        }));
  var setName = match$6[1];
  var name = match$6[0];
  var match$7 = React.useState((function () {
          return "";
        }));
  var setShortName = match$7[1];
  var shortName = match$7[0];
  var match$8 = React.useState((function () {
          return "";
        }));
  var setSupportChannelName = match$8[1];
  var supportChannelName = match$8[0];
  React.useState((function () {
          return /* RGB */0;
        }));
  var match$9 = React.useState((function () {
          return "";
        }));
  var studentLightR = match$9[0];
  var match$10 = React.useState((function () {
          return "";
        }));
  var studentLightG = match$10[0];
  var match$11 = React.useState((function () {
          return "";
        }));
  var studentLightB = match$11[0];
  var match$12 = React.useState((function () {
          return "";
        }));
  var studentMediumR = match$12[0];
  var match$13 = React.useState((function () {
          return "";
        }));
  var studentMediumG = match$13[0];
  var match$14 = React.useState((function () {
          return "";
        }));
  var studentMediumB = match$14[0];
  var match$15 = React.useState((function () {
          return "";
        }));
  var studentDarkR = match$15[0];
  var match$16 = React.useState((function () {
          return "";
        }));
  var studentDarkG = match$16[0];
  var match$17 = React.useState((function () {
          return "";
        }));
  var studentDarkB = match$17[0];
  var match$18 = React.useState((function () {
          return "";
        }));
  var tutorLightR = match$18[0];
  var match$19 = React.useState((function () {
          return "";
        }));
  var tutorLightG = match$19[0];
  var match$20 = React.useState((function () {
          return "";
        }));
  var tutorLightB = match$20[0];
  var match$21 = React.useState((function () {
          return "";
        }));
  var tutorMediumR = match$21[0];
  var match$22 = React.useState((function () {
          return "";
        }));
  var tutorMediumG = match$22[0];
  var match$23 = React.useState((function () {
          return "";
        }));
  var tutorMediumB = match$23[0];
  var match$24 = React.useState((function () {
          return "";
        }));
  var tutorDarkR = match$24[0];
  var match$25 = React.useState((function () {
          return "";
        }));
  var tutorDarkG = match$25[0];
  var match$26 = React.useState((function () {
          return "";
        }));
  var tutorDarkB = match$26[0];
  var match$27 = React.useState((function () {
          return [];
        }));
  var setSelectedAdmins = match$27[1];
  var selectedAdmins = match$27[0];
  var match$28 = React.useState((function () {
          return false;
        }));
  var setLoading = match$28[1];
  var onImageChange = function (files, type_) {
    var match = Belt_List.head(files);
    if (match !== undefined) {
      var file = Caml_option.valFromOption(match);
      var reader = new FileReader();
      reader.onload = (function ($$event) {
          if (type_) {
            return Curry._1(setLogoHeader, (function (param) {
                          return $$event.target.result;
                        }));
          } else {
            return Curry._1(setLogo, (function (param) {
                          return $$event.target.result;
                        }));
          }
        });
      reader.readAsDataURL(file);
      if (type_) {
        return Curry._1(setLogoHeaderFile, (function (param) {
                      return Caml_option.some(file);
                    }));
      } else {
        return Curry._1(setLogoFile, (function (param) {
                      return Caml_option.some(file);
                    }));
      }
    } else {
      return /* () */0;
    }
  };
  var onSelectAdmin = function (id) {
    if (selectedAdmins.includes(id)) {
      return Curry._1(setSelectedAdmins, (function (prev) {
                    return prev.filter((function (pid) {
                                  return pid !== id;
                                }));
                  }));
    } else {
      return Curry._1(setSelectedAdmins, (function (prev) {
                    return prev.concat([id]);
                  }));
    }
  };
  var onClickCreate = function (e) {
    e.preventDefault();
    if (logoFile !== undefined && logoHeaderFile !== undefined) {
      var lhfile = Caml_option.valFromOption(logoHeaderFile);
      Curry._1(setLoading, (function (param) {
              return true;
            }));
      var endpointName = name.replace(/ /g, "%20");
      var endpointLogo = "/images/organization_logo?name=" + endpointName;
      var endpointLogoHeader = "/images/organization_logoheader?name=" + endpointName;
      Request$NimbusWeb.sendFile(Caml_option.valFromOption(logoFile), endpointLogo, token, setErrorMessage, mode).then((function (logoJsonOption) {
                          if (logoJsonOption !== undefined) {
                            var data = Request$NimbusWeb.Decode.decodeSendFileResponse(Caml_option.valFromOption(logoJsonOption));
                            return Promise.resolve(data.uri);
                          } else {
                            return Promise.resolve(undefined);
                          }
                        })).then((function (uriOption) {
                        return Promise.all(/* tuple */[
                                    Request$NimbusWeb.sendFile(lhfile, endpointLogoHeader, token, setErrorMessage, mode),
                                    Promise.resolve(uriOption)
                                  ]);
                      })).then((function (param) {
                      var logoHeaderJsonOption = param[0];
                      if (logoHeaderJsonOption !== undefined) {
                        var data = Request$NimbusWeb.Decode.decodeSendFileResponse(Caml_option.valFromOption(logoHeaderJsonOption));
                        return Promise.resolve(/* tuple */[
                                    param[1],
                                    data.uri
                                  ]);
                      } else {
                        return Promise.resolve(/* tuple */[
                                    undefined,
                                    undefined
                                  ]);
                      }
                    })).then((function (param) {
                    var logoHeaderOption = param[1];
                    var logoOption = param[0];
                    if (logoOption !== undefined && logoHeaderOption !== undefined) {
                      var rgb = function (r, g, b) {
                        return "rgb(" + (r + (", " + (g + (", " + (b + ")")))));
                      };
                      return Request$NimbusWeb.send(OrganizationRequest_Create$NimbusWeb.make(name, shortName, logoOption, logoHeaderOption, rgb(studentLightR, studentLightG, studentLightB), rgb(studentMediumR, studentMediumG, studentMediumB), rgb(studentDarkR, studentDarkG, studentDarkB), rgb(tutorLightR, tutorLightG, tutorLightB), rgb(tutorMediumR, tutorMediumG, tutorMediumB), rgb(tutorDarkR, tutorDarkG, tutorDarkB), supportChannelName, selectedAdmins), token, setErrorMessage, mode);
                    } else {
                      alert("uri not found");
                      return Promise.reject(Caml_builtin_exceptions.not_found);
                    }
                  })).then((function (json) {
                  return Promise.resolve(OrganizationRequest_Create$NimbusWeb.$$Response.normalize(Curry._1(OrganizationRequest_Create$NimbusWeb.$$Response.Decode.exec, json)));
                })).then((function (organizationOption) {
                Curry._1(setLoading, (function (param) {
                        return false;
                      }));
                if (organizationOption !== undefined) {
                  Navigation$NimbusWeb.push("/home/organization/" + organizationOption._id);
                  return Promise.resolve(/* () */0);
                } else {
                  alert("Fialed to create organization");
                  return Promise.resolve(/* () */0);
                }
              })).catch((function (param) {
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              return Promise.resolve(/* () */0);
            }));
      return /* () */0;
    } else {
      alert("Images not found");
      return /* () */0;
    }
  };
  var colorTextField = function (value, set, placeholder) {
    return React.createElement(TextField$NimbusWeb.make, {
                type_: "number",
                placeholder: placeholder,
                value: value,
                onChange: (function (e) {
                    e.persist();
                    return Curry._1(set, (function (param) {
                                  return e.target.value;
                                }));
                  }),
                required: true
              });
  };
  return React.createElement(Page$NimbusWeb.make, {
              children: React.createElement(Section$NimbusWeb.make, {
                    children: React.createElement("form", {
                          onSubmit: onClickCreate
                        }, React.createElement(TextField$NimbusWeb.make, {
                              type_: "text",
                              placeholder: "Name",
                              value: name,
                              onChange: (function (e) {
                                  e.persist();
                                  return Curry._1(setName, (function (param) {
                                                return e.target.value;
                                              }));
                                }),
                              required: true
                            }), React.createElement(TextField$NimbusWeb.make, {
                              type_: "text",
                              placeholder: "Short Name",
                              value: shortName,
                              onChange: (function (e) {
                                  e.persist();
                                  return Curry._1(setShortName, (function (param) {
                                                return e.target.value;
                                              }));
                                }),
                              required: true
                            }), React.createElement(TextField$NimbusWeb.make, {
                              type_: "text",
                              placeholder: "Support Channel Name",
                              value: supportChannelName,
                              onChange: (function (e) {
                                  e.persist();
                                  return Curry._1(setSupportChannelName, (function (param) {
                                                return e.target.value;
                                              }));
                                }),
                              required: true
                            }), React.createElement(Typography$NimbusWeb.make, {
                              value: "Logo"
                            }), logo !== undefined ? React.createElement("div", undefined, React.createElement("img", {
                                    className: "org-create-logo",
                                    alt: "organization logo",
                                    src: logo
                                  }), React.createElement(Button$NimbusWeb.make, {
                                    onClick: (function (param) {
                                        return Curry._1(setLogo, (function (param) {
                                                      return ;
                                                    }));
                                      }),
                                    text: "Reset Image"
                                  })) : React.createElement("div", {
                                className: "org-create-dnd"
                              }, React.createElement(DragAndDrop$NimbusWeb.make, {
                                    className: "",
                                    onDrop: (function (e) {
                                        return onImageChange(e, /* Logo */0);
                                      }),
                                    children: React.createElement("div", undefined),
                                    showBorder: true
                                  }), React.createElement(Typography$NimbusWeb.make, {
                                    value: "or"
                                  }), React.createElement("input", {
                                    accept: "image/*",
                                    multiple: false,
                                    type: "file",
                                    onChange: (function ($$event) {
                                        return onImageChange($$event.target.files, /* Logo */0);
                                      })
                                  })), React.createElement(Typography$NimbusWeb.make, {
                              value: "App Header Logo"
                            }), logoHeader !== undefined ? React.createElement("div", undefined, React.createElement("img", {
                                    className: "org-create-logo-header",
                                    alt: "organization logo header",
                                    src: logoHeader
                                  }), React.createElement(Button$NimbusWeb.make, {
                                    onClick: (function (param) {
                                        return Curry._1(setLogoHeader, (function (param) {
                                                      return ;
                                                    }));
                                      }),
                                    text: "Reset Image"
                                  })) : React.createElement("div", {
                                className: "org-create-dnd"
                              }, React.createElement(DragAndDrop$NimbusWeb.make, {
                                    className: "",
                                    onDrop: (function (e) {
                                        return onImageChange(e, /* LogoHeader */1);
                                      }),
                                    children: React.createElement("div", undefined),
                                    showBorder: true
                                  }), React.createElement(Typography$NimbusWeb.make, {
                                    value: "or"
                                  }), React.createElement("input", {
                                    accept: "image/*",
                                    multiple: false,
                                    type: "file",
                                    onChange: (function ($$event) {
                                        return onImageChange($$event.target.files, /* LogoHeader */1);
                                      })
                                  })), React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined), React.createElement("th", undefined, React.createElement(Typography$NimbusWeb.make, {
                                              value: "Student"
                                            })), React.createElement("th", undefined, React.createElement(Typography$NimbusWeb.make, {
                                              value: "Tutor"
                                            })))), React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                                              value: "Light"
                                            })), React.createElement("td", {
                                          className: "org-create-color-td"
                                        }, colorTextField(studentLightR, match$9[1], "R"), colorTextField(studentLightG, match$10[1], "G"), colorTextField(studentLightB, match$11[1], "B")), React.createElement("td", {
                                          className: "org-create-color-td"
                                        }, colorTextField(tutorLightR, match$18[1], "R"), colorTextField(tutorLightG, match$19[1], "G"), colorTextField(tutorLightB, match$20[1], "B"))), React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                                              value: "Medium"
                                            })), React.createElement("td", {
                                          className: "org-create-color-td"
                                        }, colorTextField(studentMediumR, match$12[1], "R"), colorTextField(studentMediumG, match$13[1], "G"), colorTextField(studentMediumB, match$14[1], "B")), React.createElement("td", {
                                          className: "org-create-color-td"
                                        }, colorTextField(tutorMediumR, match$21[1], "R"), colorTextField(tutorMediumG, match$22[1], "G"), colorTextField(tutorMediumB, match$23[1], "B"))), React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                                              value: "Dark"
                                            })), React.createElement("td", {
                                          className: "org-create-color-td"
                                        }, colorTextField(studentDarkR, match$15[1], "R"), colorTextField(studentDarkG, match$16[1], "G"), colorTextField(studentDarkB, match$17[1], "B")), React.createElement("td", {
                                          className: "org-create-color-td"
                                        }, colorTextField(tutorDarkR, match$24[1], "R"), colorTextField(tutorDarkG, match$25[1], "G"), colorTextField(tutorDarkB, match$26[1], "B"))))), React.createElement(CheckListAdmin$NimbusWeb.make, {
                              selected: selectedAdmins,
                              onSelect: onSelectAdmin,
                              selectAll: false
                            }), React.createElement(Button$NimbusWeb.make, {
                              color: /* Gray */5,
                              onClick: (function (param) {
                                  return Navigation$NimbusWeb.pop(/* () */0);
                                }),
                              text: "Cancel"
                            }), React.createElement(Button$NimbusWeb.make, {
                              text: "Create"
                            }), match$28[0] ? React.createElement(Loader$NimbusWeb.Circular.make, { }) : null)
                  })
            });
}

var make = OrganizationCreate;

exports.make = make;
/*  Not a pure module */
