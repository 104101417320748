'use strict';

var React = require("react");
var UniversityList$NimbusWeb = require("./UniversityList.bs.js");
var UniversityCreate$NimbusWeb = require("./UniversityCreate.bs.js");
var UniversitySingle$NimbusWeb = require("./UniversitySingle.bs.js");

function UniversityController(Props) {
  var path = Props.path;
  if (path) {
    var id = path[0];
    if (id === "create") {
      return React.createElement(UniversityCreate$NimbusWeb.make, {
                  path: path[1]
                });
    } else {
      return React.createElement(UniversitySingle$NimbusWeb.make, {
                  id: id,
                  path: path[1]
                });
    }
  } else {
    return React.createElement(UniversityList$NimbusWeb.make, { });
  }
}

var make = UniversityController;

exports.make = make;
/* react Not a pure module */
