'use strict';

var React = require("react");
var ReactDom = require("react-dom");
var Button$NimbusWeb = require("./Button.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");
var Icon_Cancel$NimbusWeb = require("../icons/Icon_Cancel.bs.js");

require("./Alert.css");

var root = document.getElementById("root");

function Alert(Props) {
  var visible = Props.visible;
  var onClose = Props.onClose;
  var children = Props.children;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  if (visible) {
    var contentClassName = "alert-content" + (
      className === "" ? "" : " " + className
    );
    return ReactDom.createPortal(React.createElement("div", {
                    "aria-modal": true,
                    className: "alert-container",
                    role: "dialog"
                  }, React.createElement("div", {
                        className: contentClassName
                      }, children, React.createElement(Button$NimbusWeb.Icon.make, {
                            className: "alert-close-button",
                            onClick: onClose,
                            children: React.createElement(Icon_Cancel$NimbusWeb.make, {
                                  color: /* Gray1 */6
                                })
                          }))), root);
  } else {
    return null;
  }
}

function Alert$TopAdornment(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "alert-section"
            }, children);
}

var TopAdornment = {
  make: Alert$TopAdornment
};

function Alert$Title(Props) {
  var text = Props.text;
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Black */9;
  return React.createElement(Typography$NimbusWeb.make, {
              color: color,
              className: "alert-section",
              value: text
            });
}

var Title = {
  make: Alert$Title
};

function Alert$Content(Props) {
  var text = Props.text;
  return React.createElement(Typography$NimbusWeb.make, {
              textAlign: "center",
              className: "alert-section",
              value: text
            });
}

var Content = {
  make: Alert$Content
};

function Alert$Actions(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "alert-section alert-actions"
            }, children);
}

var Actions = {
  make: Alert$Actions
};

var make = Alert;

exports.root = root;
exports.make = make;
exports.TopAdornment = TopAdornment;
exports.Title = Title;
exports.Content = Content;
exports.Actions = Actions;
/*  Not a pure module */
