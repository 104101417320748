'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

var $$File = { };

function setRef(reactRef) {
  return (function (elem) {
      reactRef.current = (elem == null) ? undefined : Caml_option.some(elem);
      return /* () */0;
    });
}

function getLast(arr) {
  return Belt_Array.get(arr, arr.length - 1 | 0);
}

exports.$$File = $$File;
exports.setRef = setRef;
exports.getLast = getLast;
/* No side effect */
