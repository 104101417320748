'use strict';

var React = require("react");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");

require("./AppBarLogin.css");

var nimbusLogo = require("@assets/nimbus_logo.png").default;

var navItems = [
  {
    link: "nimbusapp.ca",
    label: "Home"
  },
  {
    link: "nimbusapp.ca",
    label: "Partners"
  },
  {
    link: "nimbusapp.ca",
    label: "Get the app"
  },
  {
    link: "nimbusapp.ca",
    label: "Blog"
  }
];

function AppBarLogin(Props) {
  return React.createElement("div", {
              className: "app-bar-login-container"
            }, React.createElement("div", {
                  className: "app-bar-login-logo-container"
                }, React.createElement("img", {
                      className: "app-bar-login-logo",
                      alt: "nimbus logo",
                      src: nimbusLogo
                    }), React.createElement(Typography$NimbusWeb.make, {
                      variant: /* H3 */2,
                      color: /* White */8,
                      value: "Nimbus Internal Console"
                    })));
}

var make = AppBarLogin;

exports.nimbusLogo = nimbusLogo;
exports.navItems = navItems;
exports.make = make;
/*  Not a pure module */
