'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");
var Storage$NimbusWeb = require("../utils/Storage.bs.js");

function toString(m) {
  switch (m) {
    case /* Production */0 :
        return "production";
    case /* Staging */1 :
        return "staging";
    case /* Other */2 :
        return "other";
    
  }
}

function fromString(s) {
  switch (s) {
    case "production" :
        return /* Production */0;
    case "staging" :
        return /* Staging */1;
    default:
      return /* Other */2;
  }
}

function defaultValue_001(param) {
  return /* () */0;
}

var defaultValue = /* tuple */[
  /* Production */0,
  defaultValue_001
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function ModeContext$Wrap(Props) {
  var children = Props.children;
  var match = Storage$NimbusWeb.get(/* Mode */1);
  var storedMode = match !== undefined ? fromString(match) : /* Production */0;
  var match$1 = React.useState((function () {
          return storedMode;
        }));
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, /* tuple */[
                  match$1[0],
                  match$1[1]
                ], children, /* () */0));
}

var Wrap = {
  make: ModeContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.toString = toString;
exports.fromString = fromString;
exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
