'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function decodeUser(json) {
  return {
          first: Json_decode.field("first", Json_decode.string, json),
          last: Json_decode.field("last", Json_decode.string, json),
          email: Json_decode.field("email", Json_decode.string, json)
        };
}

function decodeAdmin(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          user: Json_decode.field("user", decodeUser, json)
        };
}

var Decode = {
  decodeUser: decodeUser,
  decodeAdmin: decodeAdmin
};

function normalize(admin) {
  return {
          _id: admin._id,
          name: admin.user.first + (" " + admin.user.last),
          email: admin.user.email
        };
}

var fragment = "\n  fragment AdminFragment on Admin {\n    _id\n    user {\n      first\n      last\n      email\n    }\n  }\n";

exports.fragment = fragment;
exports.Decode = Decode;
exports.normalize = normalize;
/* No side effect */
