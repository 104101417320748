'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var OrganizationResponse$NimbusWeb = require("./OrganizationResponse.bs.js");

function make(param) {
  var body = { };
  var query = "\n    {\n      organizations {\n        ...OrganizationFragment\n      }\n    }\n    " + (String(OrganizationResponse$NimbusWeb.fragment) + "\n  ");
  body["query"] = query;
  return body;
}

function decodeOrganizations(json) {
  return Json_decode.array(OrganizationResponse$NimbusWeb.Decode.decodeOrganization, json);
}

function decodeData(json) {
  return {
          organizations: Json_decode.field("organizations", decodeOrganizations, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeOrganizations: decodeOrganizations,
  decodeData: decodeData,
  exec: exec
};

function normalize(result) {
  var match = result.data;
  if (match !== undefined) {
    return Belt_Array.map(match.organizations, OrganizationResponse$NimbusWeb.normalize);
  }
  
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* OrganizationResponse-NimbusWeb Not a pure module */
