'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Button$NimbusWeb = require("./Button.bs.js");
var Icon_Edit$NimbusWeb = require("../icons/Icon_Edit.bs.js");
var TextField$NimbusWeb = require("./TextField.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");

require("./Editable.css");

function Editable$Typography(Props) {
  var variantOpt = Props.variant;
  var colorOpt = Props.color;
  var textAlignOpt = Props.textAlign;
  var classNameOpt = Props.className;
  var value = Props.value;
  var onSave = Props.onSave;
  var variant = variantOpt !== undefined ? variantOpt : /* Body1 */8;
  var color = colorOpt !== undefined ? colorOpt : /* Gray */5;
  var textAlign = textAlignOpt !== undefined ? textAlignOpt : "left";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          return false;
        }));
  var setShowInput = match[1];
  var showInput = match[0];
  var match$1 = React.useState((function () {
          return value;
        }));
  var setNewText = match$1[1];
  var newText = match$1[0];
  React.useEffect((function () {
          Curry._1(setNewText, (function (param) {
                  return value;
                }));
          return ;
        }), [value]);
  return React.createElement("div", {
              className: "e-typography-container"
            }, React.createElement("div", {
                  className: "e-typography-input-icon"
                }, showInput ? React.createElement(TextField$NimbusWeb.make, {
                        type_: "text",
                        placeholder: "",
                        value: newText,
                        onChange: (function (e) {
                            e.persist();
                            return Curry._1(setNewText, (function (param) {
                                          return e.target.value;
                                        }));
                          })
                      }) : React.createElement(Typography$NimbusWeb.make, {
                        variant: variant,
                        color: color,
                        textAlign: textAlign,
                        className: "e-typography-text " + className,
                        value: value
                      }), React.createElement("div", {
                      className: "e-typography-icon",
                      onClick: (function (param) {
                          return Curry._1(setShowInput, (function (param) {
                                        return true;
                                      }));
                        })
                    }, React.createElement(Icon_Edit$NimbusWeb.make, { }))), showInput ? React.createElement("div", {
                    className: "e-typography-buttons"
                  }, React.createElement(Button$NimbusWeb.make, {
                        color: /* Gray */5,
                        onClick: (function (param) {
                            return Curry._1(setShowInput, (function (param) {
                                          return false;
                                        }));
                          }),
                        text: "Cancel"
                      }), React.createElement(Button$NimbusWeb.make, {
                        onClick: (function (param) {
                            Curry._1(onSave, newText);
                            return Curry._1(setShowInput, (function (param) {
                                          return false;
                                        }));
                          }),
                        text: "Save"
                      })) : null);
}

var Typography = {
  make: Editable$Typography
};

var $$Image = /* alias */0;

exports.$$Image = $$Image;
exports.Typography = Typography;
/*  Not a pure module */
