'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Page$NimbusWeb = require("../../components/Page.bs.js");
var Table$NimbusWeb = require("../../components/Table.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Section$NimbusWeb = require("../../components/Section.bs.js");
var Navigation$NimbusWeb = require("../../utils/Navigation.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var ModeContext$NimbusWeb = require("../../contexts/ModeContext.bs.js");
var ErrorContext$NimbusWeb = require("../../contexts/ErrorContext.bs.js");
var UniversityRequest_FetchList$NimbusWeb = require("./requests/UniversityRequest_FetchList.bs.js");

var tableHeader = [
  {
    key: "name",
    label: "Name"
  },
  {
    key: "active",
    label: "Active"
  }
];

function createTableData(data) {
  return Belt_Array.map(data, (function (d) {
                var dict = { };
                dict["_id"] = d._id;
                dict["name"] = d.name;
                dict["active"] = d.active;
                return dict;
              }));
}

function UniversityList(Props) {
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match[0];
  var match$1 = React.useContext(ModeContext$NimbusWeb.context);
  var mode = match$1[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  var match$2 = React.useState((function () {
          return ;
        }));
  var setUniversities = match$2[1];
  var universities = match$2[0];
  var fetchUniversities = function (param) {
    Request$NimbusWeb.send(UniversityRequest_FetchList$NimbusWeb.make(/* () */0), token, setErrorMessage, mode).then((function (json) {
              return Promise.resolve(UniversityRequest_FetchList$NimbusWeb.$$Response.normalize(Curry._1(UniversityRequest_FetchList$NimbusWeb.$$Response.Decode.exec, json)));
            })).then((function (data) {
            Curry._1(setUniversities, (function (param) {
                    return Belt_Option.map(data, createTableData);
                  }));
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  React.useEffect((function () {
          fetchUniversities(/* () */0);
          return ;
        }), ([]));
  var onClickRow = function (universityId) {
    if (universityId !== undefined) {
      var match = Js_json.decodeString(Caml_option.valFromOption(universityId));
      if (match !== undefined) {
        return Navigation$NimbusWeb.push("/home/university/" + match);
      } else {
        return /* () */0;
      }
    } else {
      return /* () */0;
    }
  };
  return React.createElement(Page$NimbusWeb.make, {
              children: React.createElement(Section$NimbusWeb.make, {
                    children: null
                  }, React.createElement(Button$NimbusWeb.make, {
                        onClick: (function (param) {
                            return Navigation$NimbusWeb.push("/home/university/create");
                          }),
                        text: "Create"
                      }), React.createElement(Table$NimbusWeb.make, {
                        header: tableHeader,
                        data: universities !== undefined ? universities : [{ }],
                        hover: true,
                        onClickRow: onClickRow
                      }))
            });
}

var make = UniversityList;

exports.tableHeader = tableHeader;
exports.createTableData = createTableData;
exports.make = make;
/* react Not a pure module */
