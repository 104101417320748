'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var UniversityResponse$NimbusWeb = require("./UniversityResponse.bs.js");

function make(name, shortName, longitude, latitude, photo, admins) {
  var body = { };
  var query = "\n    mutation(\$name: String!, \$shortName: String!, \$longitude: Float!, \$latitude: Float!, \$photo: String!, \$admins: [ID]!) {\n      addUniversity(input: {\n        name: \$name,\n        shortName: \$shortName,\n        coordinates: {\n          longitude: \$longitude,\n          latitude: \$latitude,\n        },\n        photo: \$photo,\n      }, admins: \$admins) {\n        ...UniversityFragment\n      }\n    }\n    " + (String(UniversityResponse$NimbusWeb.fragment) + "\n  ");
  body["query"] = query;
  var variables = { };
  variables["name"] = name;
  variables["shortName"] = shortName;
  variables["longitude"] = longitude;
  variables["latitude"] = latitude;
  variables["photo"] = photo;
  variables["admins"] = admins;
  body["variables"] = variables;
  return body;
}

function decodeData(json) {
  return {
          university: Json_decode.field("addUniversity", UniversityResponse$NimbusWeb.Decode.decodeUniversity, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(result) {
  return Belt_Option.map(result.data, (function (data) {
                return UniversityResponse$NimbusWeb.normalize(data.university);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* No side effect */
