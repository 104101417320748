'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Alert$NimbusWeb = require("../components/Alert.bs.js");
var Browser$NimbusWeb = require("../utils/Browser.bs.js");

function BrowserSupported(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return /* tuple */[
                  false,
                  true
                ];
        }));
  var setShow = match[1];
  var show = match[0];
  React.useEffect((function () {
          var shouldShow = typeof Browser$NimbusWeb.browserName === "number" && Browser$NimbusWeb.browserName !== 1 ? Browser$NimbusWeb.browserName >= 3 : true;
          Curry._1(setShow, (function (param) {
                  return /* tuple */[
                          shouldShow,
                          true
                        ];
                }));
          return ;
        }), ([]));
  var text = show[0] ? "We currently do not support your browser. We recommend using Google Chrome or Firefox to maximize the user experience we offer." : (
      show[1] ? "Works fine?" : "It seems like our video service is not supported on your browser. Please upgrade it if you can."
    );
  return React.createElement(React.Fragment, undefined, children, React.createElement(Alert$NimbusWeb.make, {
                  visible: show[0] || !show[1],
                  onClose: (function (param) {
                      return Curry._1(setShow, (function (param) {
                                    return /* tuple */[
                                            false,
                                            true
                                          ];
                                  }));
                    }),
                  children: null
                }, React.createElement(Alert$NimbusWeb.Title.make, {
                      text: "Unsupported browser"
                    }), React.createElement(Alert$NimbusWeb.Content.make, {
                      text: text
                    })));
}

var make = BrowserSupported;

exports.make = make;
/* react Not a pure module */
