'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function make(email, password) {
  var body = { };
  body["query"] = "\n    mutation login(\$email: String!, \$password: String!) {\n      loginInternal(email: \$email, password: \$password)\n    }\n  ";
  var variables = { };
  variables["email"] = email;
  variables["password"] = password;
  body["variables"] = variables;
  return body;
}

function data(json) {
  return {
          loginInternal: Json_decode.field("loginInternal", Json_decode.string, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", data, param);
                }), json)
        };
}

var Decode = {
  data: data,
  exec: exec
};

function normalize(payload) {
  var match = payload.data;
  if (match !== undefined) {
    return {
            token: match.loginInternal
          };
  } else {
    return {
            token: undefined
          };
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* No side effect */
