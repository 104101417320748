'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function decodeCoordinates(json) {
  return {
          longitude: Json_decode.field("longitude", Json_decode.$$float, json),
          latitude: Json_decode.field("latitude", Json_decode.$$float, json)
        };
}

function decodeUniversity(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          shortName: Json_decode.field("shortName", Json_decode.string, json),
          photo: Json_decode.optional((function (param) {
                  return Json_decode.field("photo", Json_decode.string, param);
                }), json),
          active: Json_decode.field("active", Json_decode.bool, json),
          coordinates: Json_decode.field("coordinates", decodeCoordinates, json)
        };
}

var Decode = {
  decodeCoordinates: decodeCoordinates,
  decodeUniversity: decodeUniversity
};

function normalize(university) {
  return {
          _id: university._id,
          name: university.name,
          shortName: university.shortName,
          photo: university.photo,
          active: university.active,
          longitude: university.coordinates.longitude,
          latitude: university.coordinates.latitude
        };
}

var fragment = "\n  fragment UniversityFragment on University {\n    _id\n    name\n    shortName\n    photo\n    active\n    coordinates {\n      longitude\n      latitude\n    }\n  }\n";

exports.fragment = fragment;
exports.Decode = Decode;
exports.normalize = normalize;
/* No side effect */
