'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function getColor(c) {
  switch (c) {
    case /* Primary */0 :
        return "#ed8079";
    case /* Secondary */1 :
        return "#81c9ef";
    case /* Error */2 :
        return "red";
    case /* Success */3 :
        return "green";
    case /* Placeholder */4 :
        return "light-gray";
    case /* Gray */5 :
        return "gray";
    case /* Gray1 */6 :
        return "#757575";
    case /* Gray3 */7 :
        return "#828282";
    case /* White */8 :
        return "white";
    case /* Black */9 :
        return "black";
    case /* Transparent */10 :
        return "transparent";
    
  }
}

function setCSSColors(set) {
  return Curry._2(set, "--color-primary", "#ed8079");
}

exports.getColor = getColor;
exports.setCSSColors = setCSSColors;
/* No side effect */
