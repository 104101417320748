'use strict';

var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function pop(prim) {
  history.back();
  return /* () */0;
}

var push = ReasonReactRouter.push;

exports.pop = pop;
exports.push = push;
/* ReasonReactRouter Not a pure module */
