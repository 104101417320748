'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var OrganizationResponse$NimbusWeb = require("./OrganizationResponse.bs.js");

function make(organizationId) {
  var body = { };
  var query = "\n    query(\$organization: ID!) {\n      organizationByID(_organization: \$organization) {\n        ...OrganizationFragment\n      }\n    }\n    " + (String(OrganizationResponse$NimbusWeb.fragment) + "\n  ");
  body["query"] = query;
  var variables = { };
  variables["organization"] = organizationId;
  body["variables"] = variables;
  return body;
}

function decodeData(json) {
  return {
          organization: Json_decode.optional((function (param) {
                  return Json_decode.field("organizationByID", OrganizationResponse$NimbusWeb.Decode.decodeOrganization, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(result) {
  var match = result.data;
  if (match !== undefined) {
    var match$1 = match.organization;
    if (match$1 !== undefined) {
      return OrganizationResponse$NimbusWeb.normalize(match$1);
    } else {
      return ;
    }
  }
  
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* OrganizationResponse-NimbusWeb Not a pure module */
