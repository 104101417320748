'use strict';

var React = require("react");
var Typography$NimbusWeb = require("./Typography.bs.js");

require("./Sidebar.css");

function Sidebar$Item(Props) {
  var active = Props.active;
  var label = Props.label;
  var onClick = Props.onClick;
  var style = {
    backgroundColor: active ? "#f3f4f8" : "white"
  };
  return React.createElement("div", {
              className: "sidebar-item",
              style: style,
              onClick: onClick
            }, React.createElement(Typography$NimbusWeb.make, {
                  color: /* Black */9,
                  value: label
                }));
}

var Item = {
  make: Sidebar$Item
};

function Sidebar$Toolbar(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "sidebar-toolbar-container"
            }, children);
}

var Toolbar = {
  make: Sidebar$Toolbar
};

function Sidebar(Props) {
  var show = Props.show;
  var children = Props.children;
  var style = {
    display: show ? "block" : "none"
  };
  return React.createElement("div", {
              className: "sidebar-container",
              style: style
            }, children);
}

var make = Sidebar;

exports.Item = Item;
exports.Toolbar = Toolbar;
exports.make = make;
/*  Not a pure module */
