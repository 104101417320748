'use strict';

var React = require("react");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var App$NimbusWeb = require("./App.bs.js");
var Theme$NimbusWeb = require("./utils/Theme.bs.js");
var AuthContext$NimbusWeb = require("./contexts/AuthContext.bs.js");
var ModeContext$NimbusWeb = require("./contexts/ModeContext.bs.js");
var UserContext$NimbusWeb = require("./contexts/UserContext.bs.js");
var ErrorContext$NimbusWeb = require("./contexts/ErrorContext.bs.js");
var LocaleContext$NimbusWeb = require("./contexts/LocaleContext.bs.js");
var BrowserSupported$NimbusWeb = require("./contexts/BrowserSupported.bs.js");

require("./Index.css");

require('es6-promise').polyfill();
;

require('isomorphic-fetch');
;

Theme$NimbusWeb.setCSSColors((function (prim, prim$1) {
        document.documentElement.style.setProperty(prim, prim$1);
        return /* () */0;
      }));

ReactDOMRe.renderToElementWithId(React.createElement(ModeContext$NimbusWeb.Wrap.make, {
          children: React.createElement(BrowserSupported$NimbusWeb.make, {
                children: React.createElement(LocaleContext$NimbusWeb.Wrap.make, {
                      children: React.createElement(ErrorContext$NimbusWeb.Wrap.make, {
                            children: React.createElement(AuthContext$NimbusWeb.Wrap.make, {
                                  children: React.createElement(UserContext$NimbusWeb.Wrap.make, {
                                        children: React.createElement(App$NimbusWeb.make, { })
                                      })
                                })
                          })
                    })
              })
        }), "root");

/*  Not a pure module */
