'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Locale$NimbusWeb = require("../utils/Locale.bs.js");
var Browser$NimbusWeb = require("../utils/Browser.bs.js");
var Context$NimbusWeb = require("./Context.bs.js");

function defaultValue_000(param, replaceListOpt, param$1) {
  return "";
}

function defaultValue_002(param) {
  return /* () */0;
}

var defaultValue = /* tuple */[
  defaultValue_000,
  /* En */0,
  defaultValue_002
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function getTextData(param) {
  return require("../texts.json");
}

function LocaleContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return /* En */0;
        }));
  var setLocale = match[1];
  var locale = match[0];
  React.useEffect((function () {
          var l = Browser$NimbusWeb.locale.indexOf("fr") !== -1 ? /* Fr */1 : /* En */0;
          Curry._1(setLocale, (function (param) {
                  return l;
                }));
          return ;
        }), ([]));
  var texts = React.useMemo((function () {
          return Locale$NimbusWeb.textsToDict(locale, require("../texts.json"));
        }), [locale]);
  var getText = function (key, replaceListOpt, param) {
    var replaceList = replaceListOpt !== undefined ? replaceListOpt : [];
    var match = Js_dict.get(texts, key);
    if (match !== undefined) {
      return Belt_Array.reduce(replaceList, match, (function (t, param) {
                    return t.replace("[" + (param[0] + "]"), param[1]);
                  }));
    } else {
      console.error("Error: key " + (String(key) + " not found in given texts."));
      return "";
    }
  };
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, /* tuple */[
                  getText,
                  locale,
                  setLocale
                ], children, /* () */0));
}

var Wrap = {
  make: LocaleContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.getTextData = getTextData;
exports.Wrap = Wrap;
/* include Not a pure module */
