'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");

function toString(param) {
  if (param) {
    return "fr";
  } else {
    return "en";
  }
}

function textsToDict(locale, texts) {
  return Belt_Array.reduce(texts, { }, (function (dict, item) {
                dict[item.id] = locale ? item.fr : item.en;
                return dict;
              }));
}

exports.toString = toString;
exports.textsToDict = textsToDict;
/* No side effect */
