'use strict';

var React = require("react");
var Icon_CheckBox$NimbusWeb = require("../icons/Icon_CheckBox.bs.js");

function CheckBox(Props) {
  var colorOpt = Props.color;
  var checked = Props.checked;
  var onClickCheck = Props.onClickCheck;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  return React.createElement("div", {
              onClick: onClickCheck
            }, checked ? React.createElement(Icon_CheckBox$NimbusWeb.make, {
                    color: color
                  }) : React.createElement(Icon_CheckBox$NimbusWeb.Empty.make, {
                    color: color
                  }));
}

var make = CheckBox;

exports.make = make;
/* react Not a pure module */
