'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Home$NimbusWeb = require("./Home.bs.js");
var Login$NimbusWeb = require("./scenes/login/Login.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Request$NimbusWeb = require("./utils/Request.bs.js");
var Storage$NimbusWeb = require("./utils/Storage.bs.js");
var Redirect$NimbusWeb = require("./components/Redirect.bs.js");
var AuthContext$NimbusWeb = require("./contexts/AuthContext.bs.js");
var ModeContext$NimbusWeb = require("./contexts/ModeContext.bs.js");
var ErrorContext$NimbusWeb = require("./contexts/ErrorContext.bs.js");
var SplashScreen$NimbusWeb = require("./scenes/login/SplashScreen.bs.js");
var LoginRequest_VerifyToken$NimbusWeb = require("./scenes/login/requests/LoginRequest_VerifyToken.bs.js");

function App(Props) {
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match[0];
  var match$1 = React.useState((function () {
          return /* Validating */0;
        }));
  var setAuthenticated = match$1[1];
  var match$2 = React.useContext(ModeContext$NimbusWeb.context);
  var mode = match$2[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  React.useEffect((function () {
          var exit = 0;
          if (token !== undefined) {
            var t = token;
            if (t !== "" && t !== "undefined") {
              Request$NimbusWeb.send(LoginRequest_VerifyToken$NimbusWeb.make(t), undefined, setErrorMessage, mode).then((function (json) {
                          return Promise.resolve(LoginRequest_VerifyToken$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_VerifyToken$NimbusWeb.$$Response.Decode.exec, json)));
                        })).then((function (data) {
                        var match = data.token;
                        if (match !== undefined) {
                          Storage$NimbusWeb.set(/* Token */0, data.token);
                          Curry._1(setAuthenticated, (function (param) {
                                  return /* Authorized */2;
                                }));
                        } else {
                          Curry._1(setAuthenticated, (function (param) {
                                  return /* Unauthorized */1;
                                }));
                        }
                        return Promise.resolve(/* () */0);
                      })).catch((function (param) {
                      Curry._1(setAuthenticated, (function (param) {
                              return /* Unauthorized */1;
                            }));
                      return Promise.resolve(/* () */0);
                    }));
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(setAuthenticated, (function (param) {
                    return /* Unauthorized */1;
                  }));
          }
          return ;
        }), [token]);
  var parseSearch = function (search) {
    if (search !== "") {
      return Belt_Array.map(search.split("&"), (function (entry) {
                    return entry.split("=");
                  }));
    } else {
      return [];
    }
  };
  switch (match$1[0]) {
    case /* Validating */0 :
        return React.createElement(SplashScreen$NimbusWeb.make, { });
    case /* Unauthorized */1 :
        var match$3 = url.path;
        if (match$3 && match$3[0] === "login" && !match$3[1]) {
          var tkn;
          if (url.search !== "") {
            var ext = parseSearch(url.search);
            var match$4 = ext.find((function (pair) {
                    return pair[0] === "t";
                  }));
            tkn = match$4 !== undefined ? Belt_Array.get(match$4, 1) : undefined;
          } else {
            tkn = undefined;
          }
          return React.createElement(Login$NimbusWeb.make, {
                      token: tkn
                    });
        }
        return React.createElement(Redirect$NimbusWeb.make, {
                    to_: "login"
                  });
    case /* Authorized */2 :
        var ext$1 = parseSearch(url.search);
        var match$5 = ext$1.find((function (pair) {
                return pair[0] === "l";
              }));
        if (match$5 !== undefined) {
          Belt_Array.get(match$5, 1);
        } else {
          undefined;
        }
        var match$6 = url.path;
        if (match$6) {
          if (match$6[0] === "home") {
            return React.createElement(Home$NimbusWeb.make, {
                        path: match$6[1]
                      });
          } else {
            return React.createElement(Redirect$NimbusWeb.make, {
                        to_: "home"
                      });
          }
        } else {
          return React.createElement(Redirect$NimbusWeb.make, {
                      to_: "home"
                    });
        }
    
  }
}

var make = App;

exports.make = make;
/* react Not a pure module */
