'use strict';

var React = require("react");
var Navigation$NimbusWeb = require("../utils/Navigation.bs.js");

function Redirect(Props) {
  var to_ = Props.to_;
  React.useEffect((function () {
          Navigation$NimbusWeb.push(to_);
          return ;
        }), ([]));
  return null;
}

var make = Redirect;

exports.make = make;
/* react Not a pure module */
