'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Storage$NimbusWeb = require("../../utils/Storage.bs.js");
var TextField$NimbusWeb = require("../../components/TextField.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var AppBarLogin$NimbusWeb = require("./AppBarLogin.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var ModeContext$NimbusWeb = require("../../contexts/ModeContext.bs.js");
var RadioButton$NimbusWeb = require("../../components/RadioButton.bs.js");
var ErrorContext$NimbusWeb = require("../../contexts/ErrorContext.bs.js");
var LocaleContext$NimbusWeb = require("../../contexts/LocaleContext.bs.js");
var LoginRequest_Login$NimbusWeb = require("./requests/LoginRequest_Login.bs.js");
var LoginRequest_ForgotPassword$NimbusWeb = require("./requests/LoginRequest_ForgotPassword.bs.js");

require("./Login.css");

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* SetEmail */0 :
        return {
                email: action[0],
                password: state.password,
                forgotPassword: state.forgotPassword,
                emailSent: state.emailSent,
                loading: state.loading
              };
    case /* SetPassword */1 :
        return {
                email: state.email,
                password: action[0],
                forgotPassword: state.forgotPassword,
                emailSent: state.emailSent,
                loading: state.loading
              };
    case /* SetForgotPassword */2 :
        return {
                email: state.email,
                password: state.password,
                forgotPassword: action[0],
                emailSent: state.emailSent,
                loading: state.loading
              };
    case /* SetEmailSent */3 :
        return {
                email: state.email,
                password: state.password,
                forgotPassword: state.forgotPassword,
                emailSent: action[0],
                loading: state.loading
              };
    case /* SetLoading */4 :
        return {
                email: state.email,
                password: state.password,
                forgotPassword: state.forgotPassword,
                emailSent: state.emailSent,
                loading: action[0]
              };
    
  }
}

function Login(Props) {
  var token = Props.token;
  var match = React.useReducer(reducer, {
        email: "",
        password: "",
        forgotPassword: false,
        emailSent: false,
        loading: false
      });
  var dispatch = match[1];
  var state = match[0];
  var onChangeEmail = function (e) {
    return Curry._1(dispatch, /* SetEmail */Block.__(0, [e.target.value]));
  };
  var onChangePassword = function (e) {
    return Curry._1(dispatch, /* SetPassword */Block.__(1, [e.target.value]));
  };
  var match$1 = React.useContext(LocaleContext$NimbusWeb.context);
  var text = match$1[0];
  var match$2 = React.useContext(AuthContext$NimbusWeb.Token.context);
  var setToken = match$2[1];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  var match$3 = React.useContext(ModeContext$NimbusWeb.context);
  var setMode = match$3[1];
  var mode = match$3[0];
  React.useEffect((function () {
          Curry._1(setToken, (function (param) {
                  return token;
                }));
          return ;
        }), ([]));
  var onSendEmail = function (param) {
    Curry._1(dispatch, /* SetLoading */Block.__(4, [true]));
    return Request$NimbusWeb.send(LoginRequest_ForgotPassword$NimbusWeb.make(state.email), undefined, setErrorMessage, mode).then((function (json) {
                      return Promise.resolve(LoginRequest_ForgotPassword$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_ForgotPassword$NimbusWeb.$$Response.Decode.exec, json)));
                    })).then((function (result) {
                    if (result !== undefined) {
                      Curry._1(dispatch, /* SetEmailSent */Block.__(3, [true]));
                    }
                    Curry._1(dispatch, /* SetForgotPassword */Block.__(2, [false]));
                    Curry._1(dispatch, /* SetLoading */Block.__(4, [false]));
                    return Promise.resolve(/* () */0);
                  })).catch((function (param) {
                  Curry._1(dispatch, /* SetLoading */Block.__(4, [false]));
                  return Promise.resolve(/* () */0);
                }));
  };
  var onLogIn = function (param) {
    Curry._1(dispatch, /* SetLoading */Block.__(4, [true]));
    return Request$NimbusWeb.send(LoginRequest_Login$NimbusWeb.make(state.email, state.password), undefined, setErrorMessage, mode).then((function (json) {
                      return Promise.resolve(LoginRequest_Login$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_Login$NimbusWeb.$$Response.Decode.exec, json)));
                    })).then((function (data) {
                    Curry._1(dispatch, /* SetLoading */Block.__(4, [false]));
                    Curry._1(setToken, (function (param) {
                            return data.token;
                          }));
                    return Promise.resolve(/* () */0);
                  })).catch((function (param) {
                  Curry._1(dispatch, /* SetLoading */Block.__(4, [false]));
                  return Promise.resolve(/* () */0);
                }));
  };
  var onClickSignIn = function (e) {
    e.preventDefault();
    var saveMode = function (param) {
      Storage$NimbusWeb.set(/* Mode */1, ModeContext$NimbusWeb.toString(mode));
      return Promise.resolve(/* () */0);
    };
    if (state.forgotPassword) {
      onSendEmail(/* () */0).then(saveMode);
      return /* () */0;
    } else {
      onLogIn(/* () */0).then(saveMode);
      return /* () */0;
    }
  };
  var onChangeMode = function (e) {
    var newMode = e.target.value;
    return Curry._1(setMode, (function (param) {
                  return ModeContext$NimbusWeb.fromString(newMode);
                }));
  };
  return React.createElement("div", {
              className: "login-container"
            }, React.createElement("div", {
                  className: "login-app-bar-login"
                }, React.createElement(AppBarLogin$NimbusWeb.make, { })), React.createElement("div", {
                  className: "login-main-content"
                }, React.createElement("div", {
                      className: "login-centered login-section-container"
                    }, React.createElement(Typography$NimbusWeb.make, {
                          color: /* Primary */0,
                          textAlign: "center",
                          className: "login-label",
                          value: state.forgotPassword ? Curry._3(text, "login.forgot_password_instruction", undefined, /* () */0) : Curry._3(text, "login.instruction", undefined, /* () */0)
                        }), React.createElement("form", {
                          className: "login-centered login-form",
                          onSubmit: onClickSignIn
                        }, React.createElement(TextField$NimbusWeb.make, {
                              className: "login-input",
                              type_: "email",
                              placeholder: Curry._3(text, "login.placeholder.email", undefined, /* () */0),
                              value: state.email,
                              onChange: onChangeEmail,
                              required: true
                            }), state.forgotPassword ? null : React.createElement(TextField$NimbusWeb.make, {
                                className: "login-input",
                                type_: "password",
                                placeholder: Curry._3(text, "login.placeholder.password", undefined, /* () */0),
                                value: state.password,
                                onChange: onChangePassword,
                                required: true
                              }), React.createElement("div", {
                              className: "login-radio-group"
                            }, React.createElement(RadioButton$NimbusWeb.make, {
                                  id: "production",
                                  name: "mode",
                                  value: "production",
                                  text: "Production",
                                  onChange: onChangeMode,
                                  checked: mode === /* Production */0
                                }), React.createElement(RadioButton$NimbusWeb.make, {
                                  id: "staging",
                                  name: "mode",
                                  value: "staging",
                                  text: "Staging",
                                  onChange: onChangeMode,
                                  checked: mode === /* Staging */1
                                })), React.createElement(Button$NimbusWeb.make, {
                              variant: /* Contained */1,
                              className: "login-signin-button",
                              disabled: state.loading,
                              text: state.forgotPassword ? Curry._3(text, "login.button.send", undefined, /* () */0) : Curry._3(text, "login.button.sign_in", undefined, /* () */0)
                            })), React.createElement(Button$NimbusWeb.make, {
                          color: /* Gray1 */6,
                          className: "login-forgot-password-button",
                          onClick: (function (param) {
                              Curry._1(dispatch, /* SetForgotPassword */Block.__(2, [!state.forgotPassword]));
                              return Curry._1(dispatch, /* SetEmailSent */Block.__(3, [false]));
                            }),
                          text: state.forgotPassword ? Curry._3(text, "login.button.nevermind", undefined, /* () */0) : Curry._3(text, "login.button.forgot_password", undefined, /* () */0)
                        }))));
}

var make = Login;

exports.reducer = reducer;
exports.make = make;
/*  Not a pure module */
