'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

require("./TextField.css");

function TextField(Props) {
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var type_ = Props.type_;
  var placeholder = Props.placeholder;
  var value = Props.value;
  var onChange = Props.onChange;
  var requiredOpt = Props.required;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var required = requiredOpt !== undefined ? requiredOpt : false;
  return React.createElement("div", {
              className: "textfield-container " + className
            }, React.createElement("input", {
                  className: "textfield-input",
                  disabled: disabled,
                  placeholder: placeholder,
                  required: required,
                  type: type_,
                  value: value,
                  onChange: onChange
                }), children !== undefined ? Caml_option.valFromOption(children) : null);
}

var make = TextField;

exports.make = make;
/*  Not a pure module */
