'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Float = require("bs-platform/lib/js/belt_Float.js");

function validate(input) {
  var b = input[2];
  var g = input[1];
  var r = input[0];
  if (r >= 0 && r < 256 && g >= 0 && g < 256 && b >= 0) {
    return b < 256;
  } else {
    return false;
  }
}

var rgbRe = /rgb\(([+-]?[0-9]*[.]?[0-9]+), *([+-]?[0-9]*[.]?[0-9]+), *([+-]?[0-9]*[.]?[0-9]+)\)/;

var rgbListRe = /\(([+-]?[0-9]*[.]?[0-9]+), *([+-]?[0-9]*[.]?[0-9]+), *([+-]?[0-9]*[.]?[0-9]+)\)/;

function getMatched(arr) {
  var match = Belt_Array.get(arr, 1);
  if (match !== undefined) {
    var match$1 = Belt_Array.get(arr, 2);
    var match$2 = Belt_Float.fromString(match);
    if (match$1 !== undefined && match$2 !== undefined) {
      var match$3 = Belt_Array.get(arr, 3);
      var match$4 = Belt_Float.fromString(match$1);
      if (match$3 !== undefined && match$4 !== undefined) {
        var match$5 = Belt_Float.fromString(match$3);
        if (match$5 !== undefined) {
          return /* tuple */[
                  match$2,
                  match$4,
                  match$5
                ];
        } else {
          return ;
        }
      } else {
        return ;
      }
    } else {
      return ;
    }
  }
  
}

function fromRgbString(rgbString) {
  var match = rgbString.match(rgbRe);
  if (match !== null) {
    return getMatched(match);
  }
  
}

function fromListString(listString) {
  var match = listString.match(rgbListRe);
  if (match !== null) {
    return getMatched(match);
  }
  
}

function toString(t) {
  return [
            t[0],
            t[1],
            t[2]
          ].join(", ");
}

exports.validate = validate;
exports.rgbRe = rgbRe;
exports.rgbListRe = rgbListRe;
exports.getMatched = getMatched;
exports.fromRgbString = fromRgbString;
exports.fromListString = fromListString;
exports.toString = toString;
/* No side effect */
