'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");

require("./Button.css");

function Button(Props) {
  var variantOpt = Props.variant;
  var colorOpt = Props.color;
  var classNameOpt = Props.className;
  var onClickOpt = Props.onClick;
  var disabledOpt = Props.disabled;
  var type_ = Props.type_;
  var text = Props.text;
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        return /* () */0;
      });
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match;
  switch (variant) {
    case /* Default */0 :
        match = /* tuple */[
          "button-default",
          { },
          color,
          "button-default-text"
        ];
        break;
    case /* Contained */1 :
        match = /* tuple */[
          "button-contained",
          {
            backgroundColor: Theme$NimbusWeb.getColor(color)
          },
          /* White */8,
          "button-contained-text"
        ];
        break;
    case /* Outlined */2 :
        match = /* tuple */[
          "button-outlined",
          {
            borderColor: Theme$NimbusWeb.getColor(color)
          },
          color,
          "button-outlined-text"
        ];
        break;
    
  }
  var cn = match[0] + ((
      disabled ? " button-disabled " : " "
    ) + className);
  var tmp = {
    className: cn,
    style: match[1],
    disabled: disabled,
    onClick: onClick
  };
  if (type_ !== undefined) {
    tmp.type = Caml_option.valFromOption(type_);
  }
  return React.createElement("button", tmp, React.createElement(Typography$NimbusWeb.make, {
                  color: match[2],
                  className: match[3],
                  value: text
                }));
}

function Button$Icon(Props) {
  var classNameOpt = Props.className;
  var disabledOpt = Props.disabled;
  var onClickOpt = Props.onClick;
  var bgColorOpt = Props.bgColor;
  var sizeOpt = Props.size;
  var type_ = Props.type_;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        return /* () */0;
      });
  var bgColor = bgColorOpt !== undefined ? bgColorOpt : /* Transparent */10;
  var size = sizeOpt !== undefined ? sizeOpt : /* Medium */1;
  var s;
  switch (size) {
    case /* Small */0 :
        s = "40px";
        break;
    case /* Medium */1 :
        s = "50px";
        break;
    case /* Large */2 :
        s = "60px";
        break;
    
  }
  var style = {
    backgroundColor: Theme$NimbusWeb.getColor(bgColor),
    minHeight: s,
    minWidth: s
  };
  var cn = "button-icon-container " + ((
      disabled ? "button-disabled " : ""
    ) + className);
  var tmp = {
    className: cn,
    style: style,
    disabled: disabled,
    onClick: onClick
  };
  if (type_ !== undefined) {
    tmp.type = Caml_option.valFromOption(type_);
  }
  return React.createElement("button", tmp, children);
}

var Icon = {
  make: Button$Icon
};

var make = Button;

exports.make = make;
exports.Icon = Icon;
/*  Not a pure module */
