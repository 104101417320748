'use strict';

var React = require("react");

function Page(Props) {
  var children = Props.children;
  var style = {
    padding: "16px"
  };
  return React.createElement("div", {
              style: style
            }, children);
}

var make = Page;

exports.make = make;
/* react Not a pure module */
