'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function make(email) {
  var body = { };
  body["query"] = "\n    mutation forgotPassword($email: String!) {\n      forgotPassword(email: $email)\n    }\n  ";
  var variables = { };
  variables["email"] = email;
  body["variables"] = variables;
  return body;
}

function data(json) {
  return {
          forgotPassword: Json_decode.optional((function (param) {
                  return Json_decode.field("forgotPassword", Json_decode.string, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", data, param);
                }), json)
        };
}

var Decode = {
  data: data,
  exec: exec
};

function normalize(payload) {
  var match = payload.data;
  if (match !== undefined) {
    var match$1 = match.forgotPassword;
    if (match$1 !== undefined) {
      var str = match$1;
      if (str === "email sent") {
        return str;
      } else {
        return ;
      }
    } else {
      return ;
    }
  }
  
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* No side effect */
