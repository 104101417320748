'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_CheckBox(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "24",
              width: "24",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M0 0h24v24H0z",
                  fill: "none"
                }), React.createElement("g", {
                  fill: c
                }, React.createElement("path", {
                      d: "M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    })));
}

function Icon_CheckBox$Empty(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "24",
              width: "24",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M0 0h24v24H0z",
                  fill: "none"
                }), React.createElement("g", {
                  fill: c
                }, React.createElement("path", {
                      d: "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
                    })));
}

var Empty = {
  make: Icon_CheckBox$Empty
};

var make = Icon_CheckBox;

exports.make = make;
exports.Empty = Empty;
/* react Not a pure module */
