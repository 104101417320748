'use strict';

var React = require("react");

function Make(Config) {
  var context = React.createContext(Config.defaultValue);
  var makeProps = function (value, children, param) {
    return {
            value: value,
            children: children
          };
  };
  var make = context.Provider;
  var Provider = {
    makeProps: makeProps,
    make: make
  };
  return {
          context: context,
          defaultValue: Config.defaultValue,
          Provider: Provider
        };
}

exports.Make = Make;
/* react Not a pure module */
