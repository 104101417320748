'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function make(token) {
  var body = { };
  body["query"] = "\n    mutation validateToken(\$token: String!) {\n      validateTokenInternal(token: \$token)\n    }\n  ";
  var variables = { };
  variables["token"] = token;
  body["variables"] = variables;
  return body;
}

function data(json) {
  return {
          validateTokenInternal: Json_decode.optional((function (param) {
                  return Json_decode.field("validateTokenInternal", Json_decode.string, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", data, param);
                }), json)
        };
}

var Decode = {
  data: data,
  exec: exec
};

function normalize(payload) {
  var match = payload.data;
  if (match !== undefined) {
    return {
            token: match.validateTokenInternal
          };
  } else {
    return {
            token: undefined
          };
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* No side effect */
