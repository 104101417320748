'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Error$NimbusWeb = require("./Error.bs.js");
var FormData$NimbusWeb = require("./FormData.bs.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

var tp = process.env.REACT_APP_CONTEXT === "development" ? "http://" : "https://";

function send(body, token, setErrorMessage, context) {
  var domain;
  switch (context) {
    case /* Production */0 :
        domain = process.env.REACT_APP_PRODUCTION_DOMAIN;
        break;
    case /* Staging */1 :
        domain = process.env.REACT_APP_STAGING_DOMAIN;
        break;
    case /* Other */2 :
        throw [
              Caml_builtin_exceptions.match_failure,
              /* tuple */[
                "Request.re",
                4,
                4
              ]
            ];
    
  }
  var tmp;
  if (token !== undefined) {
    var t = token;
    tmp = t !== "" ? "Bearer " + t : "";
  } else {
    tmp = "";
  }
  return fetch(tp + (domain + "/nimbus"), Fetch.RequestInit.make(/* Post */2, {
                          "Content-Type": "application/json",
                          Authorization: tmp
                        }, Caml_option.some(JSON.stringify(body)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (prim) {
                    return prim.json();
                  })).then((function (json) {
                  var error = Error$NimbusWeb.Decode.exec(json);
                  var match = error.errors;
                  var exit = 0;
                  if (match !== undefined) {
                    var match$1 = match;
                    if (match$1.length !== 1) {
                      exit = 1;
                    } else {
                      var err = match$1[0];
                      Curry._1(setErrorMessage, (function (param) {
                              return Error$NimbusWeb.$$process(err);
                            }));
                    }
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    Curry._1(setErrorMessage, (function (param) {
                            return ;
                          }));
                  }
                  return Promise.resolve(json);
                })).catch((function (param) {
                Curry._1(setErrorMessage, (function (param) {
                        return "Network Error";
                      }));
                return Promise.resolve(JSON.parse(" \"lol\" "));
              }));
}

function sendFile(file, endpoint, token, setErrorMessage, context) {
  var domain;
  switch (context) {
    case /* Production */0 :
        domain = process.env.REACT_APP_PRODUCTION_DOMAIN;
        break;
    case /* Staging */1 :
        domain = process.env.REACT_APP_STAGING_DOMAIN;
        break;
    case /* Other */2 :
        throw [
              Caml_builtin_exceptions.match_failure,
              /* tuple */[
                "Request.re",
                46,
                4
              ]
            ];
    
  }
  var formData = FormData$NimbusWeb.make(file);
  var tmp;
  if (token !== undefined) {
    var t = token;
    tmp = t !== "" ? "Bearer " + t : "";
  } else {
    tmp = "";
  }
  return fetch(tp + (domain + endpoint), Fetch.RequestInit.make(/* Post */2, {
                          Authorization: tmp
                        }, Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (lol) {
                    return lol.json();
                  })).then((function (json) {
                  return Promise.resolve(Caml_option.some(json));
                })).catch((function (error) {
                console.log(error);
                Curry._1(setErrorMessage, (function (param) {
                        return "Error uploading file";
                      }));
                return Promise.resolve(Caml_option.some(JSON.parse(" \"lol\" ")));
              }));
}

function decodeSendFileResponse(json) {
  return {
          uri: Json_decode.field("uri", Json_decode.string, json)
        };
}

var Decode = {
  decodeSendFileResponse: decodeSendFileResponse
};

function Make(Config) {
  var make = function (args) {
    var body = { };
    body["query"] = Config.query;
    var variables = { };
    Belt_Array.forEach(Js_dict.entries(Config.params), (function (entry) {
            var k = entry[0];
            Belt_Option.map(Js_dict.get(args, k), (function (arg) {
                    variables[k] = arg;
                    return /* () */0;
                  }));
            return /* () */0;
          }));
    body["variables"] = variables;
    return body;
  };
  return {
          make: make
        };
}

exports.tp = tp;
exports.send = send;
exports.sendFile = sendFile;
exports.Decode = Decode;
exports.Make = Make;
/* tp Not a pure module */
