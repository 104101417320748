'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Float = require("bs-platform/lib/js/belt_Float.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Page$NimbusWeb = require("../../components/Page.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Section$NimbusWeb = require("../../components/Section.bs.js");
var Editable$NimbusWeb = require("../../components/Editable.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var ModeContext$NimbusWeb = require("../../contexts/ModeContext.bs.js");
var ErrorContext$NimbusWeb = require("../../contexts/ErrorContext.bs.js");
var EditableImage$NimbusWeb = require("../../components/EditableImage.bs.js");
var UniversityRequest_Update$NimbusWeb = require("./requests/UniversityRequest_Update.bs.js");
var UniversityRequest_FetchSingle$NimbusWeb = require("./requests/UniversityRequest_FetchSingle.bs.js");

function UniversitySingle(Props) {
  var id = Props.id;
  Props.path;
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match[0];
  var match$1 = React.useContext(ModeContext$NimbusWeb.context);
  var mode = match$1[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  var match$2 = React.useState((function () {
          return ;
        }));
  var setUniversity = match$2[1];
  var university = match$2[0];
  var makeRequest = function (request, exec, normalize) {
    Request$NimbusWeb.send(request, token, setErrorMessage, mode).then((function (json) {
              return Promise.resolve(Curry._1(normalize, Curry._1(exec, json)));
            })).then((function (data) {
            Curry._1(setUniversity, (function (param) {
                    return data;
                  }));
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var fetchUniversity = function (param) {
    return makeRequest(UniversityRequest_FetchSingle$NimbusWeb.make(id), UniversityRequest_FetchSingle$NimbusWeb.$$Response.Decode.exec, UniversityRequest_FetchSingle$NimbusWeb.$$Response.normalize);
  };
  var update = function (arg, type_) {
    var params = { };
    params["university"] = id;
    switch (type_) {
      case /* Name */0 :
          params["name"] = arg;
          return makeRequest(Curry._1(UniversityRequest_Update$NimbusWeb.Name.make, params), UniversityRequest_Update$NimbusWeb.Name.$$Response.Decode.exec, UniversityRequest_Update$NimbusWeb.Name.$$Response.normalize);
      case /* ShortName */1 :
          params["shortName"] = arg;
          return makeRequest(Curry._1(UniversityRequest_Update$NimbusWeb.ShortName.make, params), UniversityRequest_Update$NimbusWeb.ShortName.$$Response.Decode.exec, UniversityRequest_Update$NimbusWeb.ShortName.$$Response.normalize);
      case /* Longitude */2 :
          var match = Belt_Float.fromString(arg);
          if (match !== undefined) {
            params["longitude"] = match;
            return makeRequest(Curry._1(UniversityRequest_Update$NimbusWeb.Longitude.make, params), UniversityRequest_Update$NimbusWeb.Longitude.$$Response.Decode.exec, UniversityRequest_Update$NimbusWeb.Longitude.$$Response.normalize);
          } else {
            alert("Longitude must be a number");
            return /* () */0;
          }
      case /* Latitude */3 :
          var match$1 = Belt_Float.fromString(arg);
          if (match$1 !== undefined) {
            params["latitude"] = match$1;
            return makeRequest(Curry._1(UniversityRequest_Update$NimbusWeb.Latitude.make, params), UniversityRequest_Update$NimbusWeb.Latitude.$$Response.Decode.exec, UniversityRequest_Update$NimbusWeb.Latitude.$$Response.normalize);
          } else {
            alert("Latitude must be a number");
            return /* () */0;
          }
      case /* Photo */4 :
          params["photo"] = arg;
          return makeRequest(Curry._1(UniversityRequest_Update$NimbusWeb.Photo.make, params), UniversityRequest_Update$NimbusWeb.Photo.$$Response.Decode.exec, UniversityRequest_Update$NimbusWeb.Photo.$$Response.normalize);
      
    }
  };
  React.useEffect((function () {
          fetchUniversity(/* () */0);
          return ;
        }), ([]));
  var onSaveImage = function (fileOption) {
    if (fileOption !== undefined && university !== undefined) {
      var endpoint = "/images/university?name=" + university.name.replace(/ /g, "%20");
      Request$NimbusWeb.sendFile(Caml_option.valFromOption(fileOption), endpoint, token, setErrorMessage, mode).then((function (jsonOption) {
                if (jsonOption !== undefined) {
                  var data = Request$NimbusWeb.Decode.decodeSendFileResponse(Caml_option.valFromOption(jsonOption));
                  return Promise.resolve(data.uri);
                } else {
                  return Promise.resolve(undefined);
                }
              })).then((function (uriOption) {
              if (uriOption !== undefined) {
                update(uriOption, /* Photo */4);
                return Promise.resolve(/* () */0);
              } else {
                alert("uri not found");
                return Promise.resolve(/* () */0);
              }
            }));
      return /* () */0;
    } else {
      alert("No university or file selected");
      return /* () */0;
    }
  };
  var tmp;
  if (university !== undefined) {
    var match$3 = university.photo;
    if (match$3 !== undefined) {
      var photo = match$3;
      tmp = photo !== "" ? React.createElement(EditableImage$NimbusWeb.make, {
              className: "",
              src: photo,
              alt: "photo",
              onSave: onSaveImage
            }) : null;
    } else {
      tmp = null;
    }
  } else {
    tmp = null;
  }
  var tmp$1;
  if (university !== undefined) {
    var uni = university;
    tmp$1 = React.createElement("table", undefined, React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                          value: "longitude"
                        })), React.createElement("td", undefined, React.createElement(Editable$NimbusWeb.Typography.make, {
                          value: String(uni.longitude),
                          onSave: (function (l) {
                              return update(l, /* Longitude */2);
                            })
                        }))), React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                          value: "latitude"
                        })), React.createElement("td", undefined, React.createElement(Editable$NimbusWeb.Typography.make, {
                          value: String(uni.latitude),
                          onSave: (function (l) {
                              return update(l, /* Latitude */3);
                            })
                        })))));
  } else {
    tmp$1 = null;
  }
  return React.createElement(Page$NimbusWeb.make, {
              children: React.createElement(Section$NimbusWeb.make, {
                    children: null
                  }, tmp, React.createElement(Editable$NimbusWeb.Typography.make, {
                        value: university !== undefined ? university.name : "Name",
                        onSave: (function (name) {
                            return update(name, /* Name */0);
                          })
                      }), React.createElement(Editable$NimbusWeb.Typography.make, {
                        value: university !== undefined ? university.shortName : "Short name",
                        onSave: (function (shortName) {
                            return update(shortName, /* ShortName */1);
                          })
                      }), React.createElement(Typography$NimbusWeb.make, {
                        value: id
                      }), React.createElement(Typography$NimbusWeb.make, {
                        value: "Coordinates:"
                      }), tmp$1)
            });
}

var make = UniversitySingle;

exports.make = make;
/* react Not a pure module */
