'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var OrganizationResponse$NimbusWeb = require("./OrganizationResponse.bs.js");

function Make(Config) {
  var include = Request$NimbusWeb.Make(Config);
  var decodeData = function (json) {
    return {
            organization: Json_decode.field("organization", OrganizationResponse$NimbusWeb.Decode.decodeOrganization, json)
          };
  };
  var exec = function (json) {
    return {
            data: Json_decode.optional((function (param) {
                    return Json_decode.field("data", decodeData, param);
                  }), json)
          };
  };
  var Decode = {
    decodeData: decodeData,
    exec: exec
  };
  var normalize = function (result) {
    return Belt_Option.map(result.data, (function (data) {
                  return OrganizationResponse$NimbusWeb.normalize(data.organization);
                }));
  };
  var $$Response = {
    Decode: Decode,
    normalize: normalize
  };
  return {
          make: include.make,
          $$Response: $$Response
        };
}

var query = "\n    mutation updateOrganizationName(\$organization: ID!, \$name: String!) {\n      organization: updateOrganizationName(_organization: \$organization, name: \$name) {\n        ...OrganizationFragment\n      }\n    }\n    " + (String(OrganizationResponse$NimbusWeb.fragment) + "\n  ");

var params = Js_dict.fromList(/* :: */[
      /* tuple */[
        "organization",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "name",
          "string"
        ],
        /* [] */0
      ]
    ]);

var include = Request$NimbusWeb.Make({
      query: query,
      params: params
    });

function decodeData(json) {
  return {
          organization: Json_decode.field("organization", OrganizationResponse$NimbusWeb.Decode.decodeOrganization, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(result) {
  return Belt_Option.map(result.data, (function (data) {
                return OrganizationResponse$NimbusWeb.normalize(data.organization);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

var Name_make = include.make;

var Name = {
  make: Name_make,
  $$Response: $$Response
};

var query$1 = "\n    mutation updateOrganizationShortName(\$organization: ID!, \$shortName: String!) {\n      organization: updateOrganizationShortName(_organization: \$organization, shortName: \$shortName) {\n        ...OrganizationFragment\n      }\n    }\n    " + (String(OrganizationResponse$NimbusWeb.fragment) + "\n  ");

var params$1 = Js_dict.fromList(/* :: */[
      /* tuple */[
        "organization",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "shortName",
          "string"
        ],
        /* [] */0
      ]
    ]);

var include$1 = Request$NimbusWeb.Make({
      query: query$1,
      params: params$1
    });

function decodeData$1(json) {
  return {
          organization: Json_decode.field("organization", OrganizationResponse$NimbusWeb.Decode.decodeOrganization, json)
        };
}

function exec$1(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData$1, param);
                }), json)
        };
}

var Decode$1 = {
  decodeData: decodeData$1,
  exec: exec$1
};

function normalize$1(result) {
  return Belt_Option.map(result.data, (function (data) {
                return OrganizationResponse$NimbusWeb.normalize(data.organization);
              }));
}

var $$Response$1 = {
  Decode: Decode$1,
  normalize: normalize$1
};

var ShortName_make = include$1.make;

var ShortName = {
  make: ShortName_make,
  $$Response: $$Response$1
};

var query$2 = "\n      mutation updateOrganizationLogo (\$organization: ID!, \$logo: String!) {\n        organization: updateOrganizationLogo(_organization: \$organization, logo: \$logo) {\n          ...OrganizationFragment\n        }\n      }\n      " + (String(OrganizationResponse$NimbusWeb.fragment) + "\n    ");

var params$2 = Js_dict.fromList(/* :: */[
      /* tuple */[
        "organization",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "logo",
          "string"
        ],
        /* [] */0
      ]
    ]);

var include$2 = Request$NimbusWeb.Make({
      query: query$2,
      params: params$2
    });

function decodeData$2(json) {
  return {
          organization: Json_decode.field("organization", OrganizationResponse$NimbusWeb.Decode.decodeOrganization, json)
        };
}

function exec$2(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData$2, param);
                }), json)
        };
}

var Decode$2 = {
  decodeData: decodeData$2,
  exec: exec$2
};

function normalize$2(result) {
  return Belt_Option.map(result.data, (function (data) {
                return OrganizationResponse$NimbusWeb.normalize(data.organization);
              }));
}

var $$Response$2 = {
  Decode: Decode$2,
  normalize: normalize$2
};

var Logo_make = include$2.make;

var Logo = {
  make: Logo_make,
  $$Response: $$Response$2
};

var query$3 = "\n      mutation updateOrganizationLogoHeader (\$organization: ID!, \$logoHeader: String!) {\n        organization: updateOrganizationLogoHeader(_organization: \$organization, logoHeader: \$logoHeader) {\n          ...OrganizationFragment\n        }\n      }\n      " + (String(OrganizationResponse$NimbusWeb.fragment) + "\n    ");

var params$3 = Js_dict.fromList(/* :: */[
      /* tuple */[
        "organization",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "logoHeader",
          "string"
        ],
        /* [] */0
      ]
    ]);

var include$3 = Request$NimbusWeb.Make({
      query: query$3,
      params: params$3
    });

function decodeData$3(json) {
  return {
          organization: Json_decode.field("organization", OrganizationResponse$NimbusWeb.Decode.decodeOrganization, json)
        };
}

function exec$3(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData$3, param);
                }), json)
        };
}

var Decode$3 = {
  decodeData: decodeData$3,
  exec: exec$3
};

function normalize$3(result) {
  return Belt_Option.map(result.data, (function (data) {
                return OrganizationResponse$NimbusWeb.normalize(data.organization);
              }));
}

var $$Response$3 = {
  Decode: Decode$3,
  normalize: normalize$3
};

var LogoHeader_make = include$3.make;

var LogoHeader = {
  make: LogoHeader_make,
  $$Response: $$Response$3
};

var fragment = OrganizationResponse$NimbusWeb.fragment;

exports.Make = Make;
exports.fragment = fragment;
exports.Name = Name;
exports.ShortName = ShortName;
exports.Logo = Logo;
exports.LogoHeader = LogoHeader;
/* query Not a pure module */
