'use strict';

var React = require("react");

function Section(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var style = {
    backgroundColor: "white",
    borderRadius: "16px"
  };
  return React.createElement("div", {
              className: className,
              style: style
            }, children);
}

var make = Section;

exports.make = make;
/* react Not a pure module */
