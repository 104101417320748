'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Button$NimbusWeb = require("./Button.bs.js");
var Storage$NimbusWeb = require("../utils/Storage.bs.js");
var Icon_Menu$NimbusWeb = require("../icons/Icon_Menu.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");
var AuthContext$NimbusWeb = require("../contexts/AuthContext.bs.js");
var LocaleContext$NimbusWeb = require("../contexts/LocaleContext.bs.js");

require("./AppBar.css");

var nimbusIcon = require("@assets/nimbus_icon.png").default;

function AppBar(Props) {
  var label = Props.label;
  var onClickMenu = Props.onClickMenu;
  var showSignOutButtonOpt = Props.showSignOutButton;
  var showSignOutButton = showSignOutButtonOpt !== undefined ? showSignOutButtonOpt : false;
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var setToken = match[1];
  var match$1 = React.useContext(LocaleContext$NimbusWeb.context);
  var onClickSignOut = function (param) {
    Storage$NimbusWeb.clear(/* () */0);
    return Curry._1(setToken, (function (param) {
                  return "";
                }));
  };
  return React.createElement("div", {
              className: "appbar-container"
            }, React.createElement("div", {
                  className: "appbar-left-container"
                }, onClickMenu !== undefined ? React.createElement(Button$NimbusWeb.Icon.make, {
                        onClick: onClickMenu,
                        children: React.createElement(Icon_Menu$NimbusWeb.make, { })
                      }) : null), React.createElement(Typography$NimbusWeb.make, {
                  color: /* White */8,
                  className: "appbar-text",
                  value: label
                }), React.createElement("div", {
                  className: "appbar-right-container"
                }, showSignOutButton ? React.createElement(Button$NimbusWeb.make, {
                        variant: /* Outlined */2,
                        color: /* White */8,
                        className: "appbar-sign-out-button",
                        onClick: onClickSignOut,
                        text: Curry._3(match$1[0], "appbar.button.sign_out", undefined, /* () */0)
                      }) : null));
}

var make = AppBar;

exports.nimbusIcon = nimbusIcon;
exports.make = make;
/*  Not a pure module */
