'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Typography$NimbusWeb = require("./Typography.bs.js");

require("./Table.css");

function convert(value) {
  var match = Js_json.decodeString(value);
  if (match !== undefined) {
    return match;
  } else {
    var match$1 = Js_json.decodeNumber(value);
    if (match$1 !== undefined) {
      return String(match$1);
    } else {
      var match$2 = Js_json.decodeBoolean(value);
      if (match$2 !== undefined) {
        if (match$2) {
          return "true";
        } else {
          return "false";
        }
      } else {
        return "No displayable type";
      }
    }
  }
}

function Table(Props) {
  var headerOpt = Props.header;
  var data = Props.data;
  var hoverOpt = Props.hover;
  var onClickRowOpt = Props.onClickRow;
  var header = headerOpt !== undefined ? headerOpt : [];
  var hover = hoverOpt !== undefined ? hoverOpt : false;
  var onClickRow = onClickRowOpt !== undefined ? onClickRowOpt : (function (param) {
        return /* () */0;
      });
  return React.createElement("table", undefined, header.length === 0 ? null : React.createElement("thead", undefined, React.createElement("tr", undefined, Belt_Array.map(header, (function (h) {
                              return React.createElement("th", {
                                          key: h.key
                                        }, React.createElement(Typography$NimbusWeb.make, {
                                              value: h.label
                                            }));
                            })))), data.length === 0 ? null : React.createElement("tbody", undefined, Belt_Array.mapWithIndex(data, (function (i, d) {
                          var className = hover ? "table-hover-row" : "";
                          return React.createElement("tr", {
                                      key: String(i),
                                      className: className,
                                      onClick: (function (param) {
                                          return Curry._1(onClickRow, Js_dict.get(d, "_id"));
                                        })
                                    }, Belt_Array.map(header, (function (h) {
                                            var match = Js_dict.get(d, h.key);
                                            return React.createElement("td", {
                                                        key: h.key
                                                      }, React.createElement(Typography$NimbusWeb.make, {
                                                            color: /* Black */9,
                                                            value: match !== undefined ? convert(Caml_option.valFromOption(match)) : "N/A"
                                                          }));
                                          })));
                        }))));
}

var make = Table;

exports.convert = convert;
exports.make = make;
/*  Not a pure module */
