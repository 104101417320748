'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Button$NimbusWeb = require("./Button.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");
var DragAndDrop$NimbusWeb = require("./DragAndDrop.bs.js");

require("./EditableImage.css");

function EditableImage(Props) {
  var classNameOpt = Props.className;
  var src = Props.src;
  var alt = Props.alt;
  var onSave = Props.onSave;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          return false;
        }));
  var setShowInput = match[1];
  var showInput = match[0];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setNewImage = match$1[1];
  var newImage = match$1[0];
  var match$2 = React.useState((function () {
          return ;
        }));
  var setNewFile = match$2[1];
  var newFile = match$2[0];
  var onImageChange = function (files) {
    var match = Belt_List.head(files);
    if (match !== undefined) {
      var file = Caml_option.valFromOption(match);
      var reader = new FileReader();
      reader.onload = (function ($$event) {
          return Curry._1(setNewImage, (function (param) {
                        return $$event.target.result;
                      }));
        });
      reader.readAsDataURL(file);
      return Curry._1(setNewFile, (function (param) {
                    return Caml_option.some(file);
                  }));
    } else {
      return /* () */0;
    }
  };
  var onCancel = function (param) {
    Curry._1(setShowInput, (function (param) {
            return false;
          }));
    return Curry._1(setNewImage, (function (param) {
                  return ;
                }));
  };
  return React.createElement(React.Fragment, undefined, showInput ? React.createElement("div", {
                    className: "editable-image-background",
                    onClick: onCancel
                  }) : null, React.createElement("div", {
                  className: "editable-image-container " + className
                }, React.createElement("img", {
                      className: "editable-image-display",
                      alt: alt,
                      src: src
                    }), React.createElement("div", {
                      className: "fill editable-image-overlay",
                      onClick: (function (param) {
                          return Curry._1(setShowInput, (function (param) {
                                        return true;
                                      }));
                        })
                    }, React.createElement(Typography$NimbusWeb.make, {
                          color: /* White */8,
                          value: "Click to edit"
                        }))), showInput ? React.createElement("div", {
                    className: "center editable-image-input-container"
                  }, newImage !== undefined ? React.createElement("img", {
                          className: "editable-image-img",
                          alt: "new image",
                          src: newImage,
                          width: "100%"
                        }) : React.createElement(React.Fragment, undefined, React.createElement(DragAndDrop$NimbusWeb.make, {
                              className: className,
                              onDrop: onImageChange,
                              children: React.createElement("div", undefined),
                              showBorder: Belt_Option.isNone(newImage)
                            }), React.createElement(Typography$NimbusWeb.make, {
                              value: "or"
                            }), React.createElement("input", {
                              className: "editable-image-input",
                              id: "editable-image-input",
                              accept: "image/*",
                              multiple: false,
                              type: "file",
                              onChange: (function ($$event) {
                                  return onImageChange($$event.target.files);
                                })
                            })), React.createElement("div", undefined, React.createElement(Button$NimbusWeb.make, {
                            color: /* Gray */5,
                            onClick: onCancel,
                            text: "Cancel"
                          }), React.createElement(Button$NimbusWeb.make, {
                            onClick: (function (param) {
                                Curry._1(onSave, newFile);
                                Curry._1(setShowInput, (function (param) {
                                        return false;
                                      }));
                                return Curry._1(setNewImage, (function (param) {
                                              return ;
                                            }));
                              }),
                            disabled: Belt_Option.isNone(newImage),
                            text: "Save"
                          }))) : null);
}

var make = EditableImage;

exports.make = make;
/*  Not a pure module */
