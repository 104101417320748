'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Request$NimbusWeb = require("../../../utils/Request.bs.js");
var UniversityResponse$NimbusWeb = require("./UniversityResponse.bs.js");

function Make(Config) {
  var include = Request$NimbusWeb.Make(Config);
  var decodeData = function (json) {
    return {
            university: Json_decode.field("university", UniversityResponse$NimbusWeb.Decode.decodeUniversity, json)
          };
  };
  var exec = function (json) {
    return {
            data: Json_decode.optional((function (param) {
                    return Json_decode.field("data", decodeData, param);
                  }), json)
          };
  };
  var Decode = {
    decodeData: decodeData,
    exec: exec
  };
  var normalize = function (result) {
    return Belt_Option.map(result.data, (function (data) {
                  return UniversityResponse$NimbusWeb.normalize(data.university);
                }));
  };
  var $$Response = {
    Decode: Decode,
    normalize: normalize
  };
  return {
          make: include.make,
          $$Response: $$Response
        };
}

var query = "\n    mutation updateUniversityName(\$university: ID!, \$name: String!) {\n      university: updateUniversityName(_university: \$university, name: \$name) {\n        ...UniversityFragment\n      }\n    }\n    " + (String(UniversityResponse$NimbusWeb.fragment) + "\n  ");

var params = Js_dict.fromList(/* :: */[
      /* tuple */[
        "university",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "name",
          "string"
        ],
        /* [] */0
      ]
    ]);

var include = Request$NimbusWeb.Make({
      query: query,
      params: params
    });

function decodeData(json) {
  return {
          university: Json_decode.field("university", UniversityResponse$NimbusWeb.Decode.decodeUniversity, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(result) {
  return Belt_Option.map(result.data, (function (data) {
                return UniversityResponse$NimbusWeb.normalize(data.university);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

var Name_make = include.make;

var Name = {
  make: Name_make,
  $$Response: $$Response
};

var query$1 = "\n    mutation updateUniversityShortName(\$university: ID!, \$shortName: String!) {\n      university: updateUniversityShortName(_university: \$university, shortName: \$shortName) {\n        ...UniversityFragment\n      }\n    }\n    " + (String(UniversityResponse$NimbusWeb.fragment) + "\n  ");

var params$1 = Js_dict.fromList(/* :: */[
      /* tuple */[
        "university",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "shortName",
          "string"
        ],
        /* [] */0
      ]
    ]);

var include$1 = Request$NimbusWeb.Make({
      query: query$1,
      params: params$1
    });

function decodeData$1(json) {
  return {
          university: Json_decode.field("university", UniversityResponse$NimbusWeb.Decode.decodeUniversity, json)
        };
}

function exec$1(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData$1, param);
                }), json)
        };
}

var Decode$1 = {
  decodeData: decodeData$1,
  exec: exec$1
};

function normalize$1(result) {
  return Belt_Option.map(result.data, (function (data) {
                return UniversityResponse$NimbusWeb.normalize(data.university);
              }));
}

var $$Response$1 = {
  Decode: Decode$1,
  normalize: normalize$1
};

var ShortName_make = include$1.make;

var ShortName = {
  make: ShortName_make,
  $$Response: $$Response$1
};

var query$2 = "\n    mutation updateUniversityLongitude(\$university: ID!, \$longitude: Float!) {\n      university: updateUniversityLongitude(_university: \$university, longitude: \$longitude) {\n        ...UniversityFragment\n      }\n    }\n    " + (String(UniversityResponse$NimbusWeb.fragment) + "\n  ");

var params$2 = Js_dict.fromList(/* :: */[
      /* tuple */[
        "university",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "longitude",
          "float"
        ],
        /* [] */0
      ]
    ]);

var include$2 = Request$NimbusWeb.Make({
      query: query$2,
      params: params$2
    });

function decodeData$2(json) {
  return {
          university: Json_decode.field("university", UniversityResponse$NimbusWeb.Decode.decodeUniversity, json)
        };
}

function exec$2(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData$2, param);
                }), json)
        };
}

var Decode$2 = {
  decodeData: decodeData$2,
  exec: exec$2
};

function normalize$2(result) {
  return Belt_Option.map(result.data, (function (data) {
                return UniversityResponse$NimbusWeb.normalize(data.university);
              }));
}

var $$Response$2 = {
  Decode: Decode$2,
  normalize: normalize$2
};

var Longitude_make = include$2.make;

var Longitude = {
  make: Longitude_make,
  $$Response: $$Response$2
};

var query$3 = "\n    mutation updateUniversityLatitude(\$university: ID!, \$latitude: Float!) {\n      university: updateUniversityLatitude(_university: \$university, latitude: \$latitude) {\n        ...UniversityFragment\n      }\n    }\n    " + (String(UniversityResponse$NimbusWeb.fragment) + "\n  ");

var params$3 = Js_dict.fromList(/* :: */[
      /* tuple */[
        "university",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "latitude",
          "float"
        ],
        /* [] */0
      ]
    ]);

var include$3 = Request$NimbusWeb.Make({
      query: query$3,
      params: params$3
    });

function decodeData$3(json) {
  return {
          university: Json_decode.field("university", UniversityResponse$NimbusWeb.Decode.decodeUniversity, json)
        };
}

function exec$3(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData$3, param);
                }), json)
        };
}

var Decode$3 = {
  decodeData: decodeData$3,
  exec: exec$3
};

function normalize$3(result) {
  return Belt_Option.map(result.data, (function (data) {
                return UniversityResponse$NimbusWeb.normalize(data.university);
              }));
}

var $$Response$3 = {
  Decode: Decode$3,
  normalize: normalize$3
};

var Latitude_make = include$3.make;

var Latitude = {
  make: Latitude_make,
  $$Response: $$Response$3
};

var query$4 = "\n    mutation updateUniversityPhoto(\$university: ID!, \$photo: String!) {\n      university: updateUniversityPhoto(_university: \$university, photo: \$photo) {\n        ...UniversityFragment\n      }\n    }\n    " + (String(UniversityResponse$NimbusWeb.fragment) + "\n  ");

var params$4 = Js_dict.fromList(/* :: */[
      /* tuple */[
        "university",
        "string"
      ],
      /* :: */[
        /* tuple */[
          "photo",
          "string"
        ],
        /* [] */0
      ]
    ]);

var include$4 = Request$NimbusWeb.Make({
      query: query$4,
      params: params$4
    });

function decodeData$4(json) {
  return {
          university: Json_decode.field("university", UniversityResponse$NimbusWeb.Decode.decodeUniversity, json)
        };
}

function exec$4(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData$4, param);
                }), json)
        };
}

var Decode$4 = {
  decodeData: decodeData$4,
  exec: exec$4
};

function normalize$4(result) {
  return Belt_Option.map(result.data, (function (data) {
                return UniversityResponse$NimbusWeb.normalize(data.university);
              }));
}

var $$Response$4 = {
  Decode: Decode$4,
  normalize: normalize$4
};

var Photo_make = include$4.make;

var Photo = {
  make: Photo_make,
  $$Response: $$Response$4
};

var fragment = UniversityResponse$NimbusWeb.fragment;

exports.Make = Make;
exports.fragment = fragment;
exports.Name = Name;
exports.ShortName = ShortName;
exports.Longitude = Longitude;
exports.Latitude = Latitude;
exports.Photo = Photo;
/* query Not a pure module */
