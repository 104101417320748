'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Context$NimbusWeb = require("./Context.bs.js");
var Request$NimbusWeb = require("../utils/Request.bs.js");
var AuthContext$NimbusWeb = require("./AuthContext.bs.js");
var ModeContext$NimbusWeb = require("./ModeContext.bs.js");
var ErrorContext$NimbusWeb = require("./ErrorContext.bs.js");
var LoginRequest_Profile$NimbusWeb = require("../scenes/login/requests/LoginRequest_Profile.bs.js");

function defaultValue_001(param) {
  return /* () */0;
}

var defaultValue = /* tuple */[
  undefined,
  defaultValue_001
];

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function UserContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return ;
        }));
  var setProfile = match[1];
  var match$1 = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match$1[0];
  var match$2 = React.useContext(ModeContext$NimbusWeb.context);
  var mode = match$2[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  React.useEffect((function () {
          if (token !== undefined) {
            var t = token;
            if (t !== "" && t !== "undefined") {
              Request$NimbusWeb.send(LoginRequest_Profile$NimbusWeb.make(/* () */0), token, setErrorMessage, mode).then((function (json) {
                        return Promise.resolve(LoginRequest_Profile$NimbusWeb.$$Response.normalize(Curry._1(LoginRequest_Profile$NimbusWeb.$$Response.Decode.exec, json)));
                      })).then((function (user) {
                      Curry._1(setProfile, (function (param) {
                              return user;
                            }));
                      return Promise.resolve(/* () */0);
                    }));
            }
            
          }
          return ;
        }), [token]);
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, /* tuple */[
                  match[0],
                  setProfile
                ], children, /* () */0));
}

var Wrap = {
  make: UserContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
