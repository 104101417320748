'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

var colorsFragment = "\n  fragment ColorsFragment on Colors {\n    light\n    medium\n    dark\n  }\n";

var fragment = "\n  fragment OrganizationFragment on Organization {\n    _id\n    name\n    shortName\n    currency\n    logo\n    logoHeader\n    colors {\n      student {\n        ...ColorsFragment\n      }\n      tutor {\n        ...ColorsFragment\n      }\n    }\n  }\n  " + (String(colorsFragment) + "\n");

function decodeColors(json) {
  return {
          light: Json_decode.field("light", Json_decode.string, json),
          medium: Json_decode.field("medium", Json_decode.string, json),
          dark: Json_decode.field("dark", Json_decode.string, json)
        };
}

function decodeStudentTutorColors(json) {
  return {
          student: Json_decode.field("student", decodeColors, json),
          tutor: Json_decode.field("tutor", decodeColors, json)
        };
}

function decodeOrganization(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          shortName: Json_decode.field("shortName", Json_decode.string, json),
          currency: Json_decode.field("currency", Json_decode.string, json),
          colors: Json_decode.optional((function (param) {
                  return Json_decode.field("colors", decodeStudentTutorColors, param);
                }), json),
          logo: Json_decode.optional((function (param) {
                  return Json_decode.field("logo", Json_decode.string, param);
                }), json),
          logoHeader: Json_decode.optional((function (param) {
                  return Json_decode.field("logoHeader", Json_decode.string, param);
                }), json)
        };
}

function decodeData(json) {
  return {
          organization: Json_decode.field("organization", decodeOrganization, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeColors: decodeColors,
  decodeStudentTutorColors: decodeStudentTutorColors,
  decodeOrganization: decodeOrganization,
  decodeData: decodeData,
  exec: exec
};

function normalize(organization) {
  return {
          _id: organization._id,
          name: organization.name,
          shortName: organization.shortName,
          currency: organization.currency,
          colors: organization.colors,
          logo: organization.logo,
          logoHeader: organization.logoHeader
        };
}

exports.colorsFragment = colorsFragment;
exports.fragment = fragment;
exports.Decode = Decode;
exports.normalize = normalize;
/* fragment Not a pure module */
