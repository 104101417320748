'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Float = require("bs-platform/lib/js/belt_Float.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Page$NimbusWeb = require("../../components/Page.bs.js");
var Button$NimbusWeb = require("../../components/Button.bs.js");
var Loader$NimbusWeb = require("../../components/Loader.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Section$NimbusWeb = require("../../components/Section.bs.js");
var TextField$NimbusWeb = require("../../components/TextField.bs.js");
var Navigation$NimbusWeb = require("../../utils/Navigation.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var DragAndDrop$NimbusWeb = require("../../components/DragAndDrop.bs.js");
var ModeContext$NimbusWeb = require("../../contexts/ModeContext.bs.js");
var ErrorContext$NimbusWeb = require("../../contexts/ErrorContext.bs.js");
var CheckListAdmin$NimbusWeb = require("../../components/CheckListAdmin.bs.js");
var UniversityRequest_Create$NimbusWeb = require("./requests/UniversityRequest_Create.bs.js");

require("./UniversityCreate.css");

function UniversityCreate(Props) {
  Props.path;
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match[0];
  var match$1 = React.useContext(ModeContext$NimbusWeb.context);
  var mode = match$1[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  var match$2 = React.useState((function () {
          return ;
        }));
  var setImage = match$2[1];
  var image = match$2[0];
  var match$3 = React.useState((function () {
          return ;
        }));
  var setFile = match$3[1];
  var file = match$3[0];
  var match$4 = React.useState((function () {
          return "";
        }));
  var setName = match$4[1];
  var name = match$4[0];
  var match$5 = React.useState((function () {
          return "";
        }));
  var setShortName = match$5[1];
  var shortName = match$5[0];
  var match$6 = React.useState((function () {
          return "";
        }));
  var setLongitude = match$6[1];
  var longitude = match$6[0];
  var match$7 = React.useState((function () {
          return "";
        }));
  var setLatitude = match$7[1];
  var latitude = match$7[0];
  var match$8 = React.useState((function () {
          return [];
        }));
  var setSelectedAdmins = match$8[1];
  var selectedAdmins = match$8[0];
  var match$9 = React.useState((function () {
          return false;
        }));
  var setLoading = match$9[1];
  var onImageChange = function (files) {
    var match = Belt_List.head(files);
    if (match !== undefined) {
      var file = Caml_option.valFromOption(match);
      var reader = new FileReader();
      reader.onload = (function ($$event) {
          return Curry._1(setImage, (function (param) {
                        return $$event.target.result;
                      }));
        });
      reader.readAsDataURL(file);
      return Curry._1(setFile, (function (param) {
                    return Caml_option.some(file);
                  }));
    } else {
      return /* () */0;
    }
  };
  var onSelectAdmin = function (id) {
    if (selectedAdmins.includes(id)) {
      return Curry._1(setSelectedAdmins, (function (prev) {
                    return prev.filter((function (pid) {
                                  return pid !== id;
                                }));
                  }));
    } else {
      return Curry._1(setSelectedAdmins, (function (prev) {
                    return prev.concat([id]);
                  }));
    }
  };
  var onClickCreate = function (e) {
    e.preventDefault();
    if (file !== undefined) {
      Curry._1(setLoading, (function (param) {
              return true;
            }));
      var endpoint = "/images/university?name=" + name.replace(/ /g, "%20");
      Request$NimbusWeb.sendFile(Caml_option.valFromOption(file), endpoint, token, setErrorMessage, mode).then((function (jsonOption) {
                      if (jsonOption !== undefined) {
                        var data = Request$NimbusWeb.Decode.decodeSendFileResponse(Caml_option.valFromOption(jsonOption));
                        return Promise.resolve(data.uri);
                      } else {
                        return Promise.resolve(undefined);
                      }
                    })).then((function (uriOption) {
                    if (uriOption !== undefined) {
                      var match = Belt_Float.fromString(longitude);
                      var match$1 = Belt_Float.fromString(latitude);
                      return Request$NimbusWeb.send(UniversityRequest_Create$NimbusWeb.make(name, shortName, match !== undefined ? match : 0, match$1 !== undefined ? match$1 : 0, uriOption, selectedAdmins), token, setErrorMessage, mode);
                    } else {
                      alert("uri not found");
                      return Promise.resolve(JSON.parse(" \"lol\" "));
                    }
                  })).then((function (json) {
                  return Promise.resolve(UniversityRequest_Create$NimbusWeb.$$Response.normalize(Curry._1(UniversityRequest_Create$NimbusWeb.$$Response.Decode.exec, json)));
                })).then((function (universityOption) {
                Curry._1(setLoading, (function (param) {
                        return false;
                      }));
                if (universityOption !== undefined) {
                  Navigation$NimbusWeb.push("/home/university/" + universityOption._id);
                  return Promise.resolve(/* () */0);
                } else {
                  alert("Failed to create university");
                  return Promise.resolve(/* () */0);
                }
              })).catch((function (param) {
              Curry._1(setLoading, (function (param) {
                      return false;
                    }));
              return Promise.resolve(/* () */0);
            }));
      return /* () */0;
    } else {
      alert("Image not found");
      return /* () */0;
    }
  };
  return React.createElement(Page$NimbusWeb.make, {
              children: React.createElement(Section$NimbusWeb.make, {
                    children: React.createElement("form", {
                          onSubmit: onClickCreate
                        }, React.createElement(TextField$NimbusWeb.make, {
                              type_: "text",
                              placeholder: "Name",
                              value: name,
                              onChange: (function (e) {
                                  e.persist();
                                  return Curry._1(setName, (function (param) {
                                                return e.target.value;
                                              }));
                                }),
                              required: true
                            }), React.createElement(TextField$NimbusWeb.make, {
                              type_: "text",
                              placeholder: "Short Name",
                              value: shortName,
                              onChange: (function (e) {
                                  e.persist();
                                  return Curry._1(setShortName, (function (param) {
                                                return e.target.value;
                                              }));
                                }),
                              required: true
                            }), React.createElement(TextField$NimbusWeb.make, {
                              type_: "number",
                              placeholder: "Longitude",
                              value: longitude,
                              onChange: (function (e) {
                                  e.persist();
                                  return Curry._1(setLongitude, (function (param) {
                                                return e.target.value;
                                              }));
                                }),
                              required: true
                            }), React.createElement(TextField$NimbusWeb.make, {
                              type_: "number",
                              placeholder: "Latitude",
                              value: latitude,
                              onChange: (function (e) {
                                  e.persist();
                                  return Curry._1(setLatitude, (function (param) {
                                                return e.target.value;
                                              }));
                                }),
                              required: true
                            }), image !== undefined ? React.createElement("div", undefined, React.createElement("img", {
                                    alt: "university photo",
                                    src: image
                                  }), React.createElement(Button$NimbusWeb.make, {
                                    onClick: (function (param) {
                                        return Curry._1(setImage, (function (param) {
                                                      return ;
                                                    }));
                                      }),
                                    text: "Reset Image"
                                  })) : React.createElement("div", {
                                className: "uni-create-dnd"
                              }, React.createElement(DragAndDrop$NimbusWeb.make, {
                                    className: "",
                                    onDrop: onImageChange,
                                    children: React.createElement("div", undefined),
                                    showBorder: true
                                  }), React.createElement(Typography$NimbusWeb.make, {
                                    value: "or"
                                  }), React.createElement("input", {
                                    accept: "image/*",
                                    multiple: false,
                                    type: "file",
                                    onChange: (function ($$event) {
                                        return onImageChange($$event.target.files);
                                      })
                                  })), React.createElement(CheckListAdmin$NimbusWeb.make, {
                              selected: selectedAdmins,
                              onSelect: onSelectAdmin,
                              selectAll: false
                            }), React.createElement(Button$NimbusWeb.make, {
                              color: /* Gray */5,
                              onClick: (function (param) {
                                  return Navigation$NimbusWeb.pop(/* () */0);
                                }),
                              text: "Cancel"
                            }), React.createElement(Button$NimbusWeb.make, {
                              text: "Create"
                            }), match$9[0] ? React.createElement(Loader$NimbusWeb.Circular.make, { }) : null)
                  })
            });
}

var make = UniversityCreate;

exports.make = make;
/*  Not a pure module */
