'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

function Icon_Edit(Props) {
  var colorOpt = Props.color;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("svg", {
              height: "24px",
              width: "24px",
              version: "1.1",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  fill: c
                }, React.createElement("path", {
                      d: "M14.92381721,4.04425041l4.88214196,4.88209658l-12.35804946,12.35804946L2.56853577,16.40229987L14.92381721,4.04425041z M23.51056062,2.86680066l-2.17724745-2.17724745\n\t\tc-0.84143097264317-0.84143097264317-2.2077409864643345-0.84143097264317-3.052030727983982,0l-2.085585338599619, 2.085585338599619l4.882141959050783, 4.882141959050783l2.4327215593147273, -2.4327215593147273\n\t\tC24.16317671237798,4.571897470027359,24.16317671237798,3.5194167506461533,23.510560617433576,2.8668006557017502z M0.013613185126082674,23.264479607637757c-0.08884872158956625,0.3998646244368017,0.27217294795414626,0.7581636569552976,0.6720829496747016,0.6609201378713138l5.440327926503926,-1.3190722614336574\n\t\tL1.2466501165628976,17.724230902308378L0.013613185126082674,23.264479607637757z"
                    })));
}

var make = Icon_Edit;

exports.make = make;
/* react Not a pure module */
