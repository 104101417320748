'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function payload(json) {
  return {
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json)
        };
}

function error(json) {
  return {
          message: Json_decode.optional((function (param) {
                  return Json_decode.field("message", Json_decode.string, param);
                }), json),
          code: Json_decode.optional((function (param) {
                  return Json_decode.field("code", Json_decode.string, param);
                }), json),
          payload: Json_decode.optional((function (param) {
                  return Json_decode.field("payload", payload, param);
                }), json)
        };
}

function exec(json) {
  return {
          errors: Json_decode.optional((function (param) {
                  return Json_decode.field("errors", (function (param) {
                                return Json_decode.array(error, param);
                              }), param);
                }), json)
        };
}

var Decode = {
  payload: payload,
  error: error,
  exec: exec
};

function $$process(error) {
  var match = error.code;
  if (match !== undefined) {
    var code = match;
    switch (code) {
      case "account_not_found" :
          return "Your account was not found";
      case "incorrect_password" :
          return "Incorrect email/password combination";
      case "operation_failed" :
          var match$1 = error.payload;
          if (match$1 !== undefined) {
            var match$2 = match$1.error;
            if (match$2 !== undefined) {
              return match$2;
            } else {
              return "Unknown error";
            }
          } else {
            return "Unknown error";
          }
      case "permission_denied" :
          return "You do not have access to that action";
      default:
        return code;
    }
  } else {
    var match$3 = error.message;
    if (match$3 !== undefined) {
      return match$3;
    } else {
      return "Unknown error";
    }
  }
}

exports.Decode = Decode;
exports.$$process = $$process;
/* No side effect */
