'use strict';

var React = require("react");
var Typography$NimbusWeb = require("./Typography.bs.js");

function RadioButton(Props) {
  var id = Props.id;
  var name = Props.name;
  var value = Props.value;
  var text = Props.text;
  var onChange = Props.onChange;
  var checked = Props.checked;
  return React.createElement(React.Fragment, undefined, checked !== undefined ? React.createElement("input", {
                    id: id,
                    checked: checked,
                    name: name,
                    type: "radio",
                    value: value,
                    onChange: onChange
                  }) : React.createElement("input", {
                    id: id,
                    name: name,
                    type: "radio",
                    value: value,
                    onChange: onChange
                  }), React.createElement("label", {
                  htmlFor: id
                }, React.createElement(Typography$NimbusWeb.make, {
                      color: /* Black */9,
                      value: text
                    })));
}

var make = RadioButton;

exports.make = make;
/* react Not a pure module */
