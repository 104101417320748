'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function make(param) {
  var body = { };
  body["query"] = "\n    query {\n      getUser {\n        _id\n        locale\n      }\n    }\n  ";
  return body;
}

function decodeUser(json) {
  return {
          _id: Json_decode.field("_id", Json_decode.string, json),
          locale: Json_decode.optional((function (param) {
                  return Json_decode.field("locale", Json_decode.string, param);
                }), json)
        };
}

function decodeData(json) {
  return {
          user: Json_decode.optional((function (param) {
                  return Json_decode.field("getUser", decodeUser, param);
                }), json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeUser: decodeUser,
  decodeData: decodeData,
  exec: exec
};

function normalize(payload) {
  var $$default = {
    _id: "",
    locale: undefined
  };
  var match = payload.data;
  if (match !== undefined) {
    var match$1 = match.user;
    if (match$1 !== undefined) {
      var user = match$1;
      return {
              _id: user._id,
              locale: user.locale
            };
    } else {
      return $$default;
    }
  } else {
    return $$default;
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* No side effect */
