'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var OrganizationResponse$NimbusWeb = require("./OrganizationResponse.bs.js");

function make(name, shortName, logo, logoHeader, studentLight, studentMedium, studentDark, tutorLight, tutorMedium, tutorDark, supportChannel, admins) {
  var body = { };
  var query = "\n    mutation(\$name: String!, \$shortName: String!, \$studentLight: String!, \$studentMedium: String!, \$studentDark: String!, \$tutorLight: String!, \$tutorMedium: String!, \$tutorDark: String!, \$logo: String!, \$logoHeader: String!, \$admins: [ID]!, \$supportChannel: String!) {\n      addOrganization(input: {\n        name: \$name,\n        shortName: \$shortName,\n        colors: {\n          student: {\n            light: \$studentLight,\n            medium: \$studentMedium,\n            dark: \$studentDark,\n          },\n          tutor: {\n            light: \$tutorLight,\n            medium: \$tutorMedium,\n            dark: \$tutorDark,\n          },\n        },\n        logo: \$logo,\n        logoHeader: \$logoHeader,\n        supportChannel: \$supportChannel\n      }, admins: \$admins) {\n        ...OrganizationFragment\n      }\n    }\n    " + (String(OrganizationResponse$NimbusWeb.fragment) + "\n  ");
  body["query"] = query;
  var variables = { };
  variables["name"] = name;
  variables["shortName"] = shortName;
  variables["logo"] = logo;
  variables["logoHeader"] = logoHeader;
  variables["supportChannel"] = supportChannel;
  variables["studentLight"] = studentLight;
  variables["studentMedium"] = studentMedium;
  variables["studentDark"] = studentDark;
  variables["tutorLight"] = tutorLight;
  variables["tutorMedium"] = tutorMedium;
  variables["tutorDark"] = tutorDark;
  variables["admins"] = admins;
  body["variables"] = variables;
  return body;
}

function decodeData(json) {
  return {
          organization: Json_decode.field("addOrganization", OrganizationResponse$NimbusWeb.Decode.decodeOrganization, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeData: decodeData,
  exec: exec
};

function normalize(result) {
  return Belt_Option.map(result.data, (function (data) {
                return OrganizationResponse$NimbusWeb.normalize(data.organization);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* OrganizationResponse-NimbusWeb Not a pure module */
