'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var AppBar$NimbusWeb = require("./components/AppBar.bs.js");
var Sidebar$NimbusWeb = require("./components/Sidebar.bs.js");
var Redirect$NimbusWeb = require("./components/Redirect.bs.js");
var Navigation$NimbusWeb = require("./utils/Navigation.bs.js");
var Typography$NimbusWeb = require("./components/Typography.bs.js");
var ModeContext$NimbusWeb = require("./contexts/ModeContext.bs.js");
var UniversityController$NimbusWeb = require("./scenes/university/UniversityController.bs.js");
var OrganizationController$NimbusWeb = require("./scenes/organization/OrganizationController.bs.js");

require("./Home.css");

var pageList = [
  {
    id: "organization",
    label: "Organizations"
  },
  {
    id: "university",
    label: "Universities"
  },
  {
    id: "admin",
    label: "Admins"
  }
];

function Home(Props) {
  var path = Props.path;
  var match = React.useContext(ModeContext$NimbusWeb.context);
  var match$1 = React.useState((function () {
          return pageList[0].label;
        }));
  var setSelected = match$1[1];
  var match$2 = React.useState((function () {
          return true;
        }));
  var setShowSidebar = match$2[1];
  var showSidebar = match$2[0];
  var content;
  var exit = 0;
  if (path) {
    switch (path[0]) {
      case "admin" :
          if (path[1]) {
            exit = 1;
          } else {
            content = React.createElement("div", undefined, "admins");
          }
          break;
      case "organization" :
          content = React.createElement(OrganizationController$NimbusWeb.make, {
                path: path[1]
              });
          break;
      case "university" :
          content = React.createElement(UniversityController$NimbusWeb.make, {
                path: path[1]
              });
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    content = React.createElement(Redirect$NimbusWeb.make, {
          to_: "home/organization"
        });
  }
  var contentStyle = {
    marginLeft: showSidebar ? "200px" : "0"
  };
  var overlayStyle = {
    display: showSidebar ? "block" : "none"
  };
  var match$3 = pageList.find((function (page) {
          var match = Belt_List.head(path);
          if (match !== undefined) {
            return match === page.id;
          } else {
            return false;
          }
        }));
  return React.createElement("div", {
              style: {
                position: "relative"
              }
            }, React.createElement(Sidebar$NimbusWeb.make, {
                  show: showSidebar,
                  children: null
                }, React.createElement(Sidebar$NimbusWeb.Toolbar.make, {
                      children: React.createElement("div", {
                            className: "home-toolbar"
                          }, React.createElement("img", {
                                height: "38px",
                                src: "https://s3.us-east-2.amazonaws.com/dev.testnimbus/nimbus_logo.png",
                                width: "38px"
                              }), React.createElement("div", {
                                className: "home-toolbar-text-container"
                              }, React.createElement(Typography$NimbusWeb.make, {
                                    color: /* Primary */0,
                                    className: "home-toolbar-text",
                                    value: "Nimbus"
                                  }), React.createElement(Typography$NimbusWeb.make, {
                                    className: "home-toolbar-text",
                                    value: "Internal Console"
                                  })))
                    }), React.createElement("div", {
                      className: "home-viewing-data-context"
                    }, React.createElement(Typography$NimbusWeb.make, {
                          value: "Viewing data for:"
                        }), React.createElement(Typography$NimbusWeb.make, {
                          color: /* Primary */0,
                          value: ModeContext$NimbusWeb.toString(match[0])
                        })), Belt_Array.map(pageList, (function (page) {
                        var match = Belt_List.head(path);
                        return React.createElement(Sidebar$NimbusWeb.Item.make, {
                                    active: match !== undefined ? match === page.id : false,
                                    label: page.label,
                                    onClick: (function (param) {
                                        Navigation$NimbusWeb.push("/home/" + page.id);
                                        return Curry._1(setSelected, (function (param) {
                                                      return page.label;
                                                    }));
                                      }),
                                    key: page.label
                                  });
                      }))), React.createElement("div", {
                  className: "home-content-container",
                  style: contentStyle
                }, React.createElement(AppBar$NimbusWeb.make, {
                      label: match$3 !== undefined ? match$3.label : "Title",
                      onClickMenu: (function (param) {
                          return Curry._1(setShowSidebar, (function (param) {
                                        return !showSidebar;
                                      }));
                        }),
                      showSignOutButton: true
                    }), React.createElement("div", {
                      className: "home-content"
                    }, content), React.createElement("div", {
                      className: "home-overlay",
                      style: overlayStyle,
                      onClick: (function (param) {
                          return Curry._1(setShowSidebar, (function (param) {
                                        return false;
                                      }));
                        })
                    })));
}

var make = Home;

exports.pageList = pageList;
exports.make = make;
/*  Not a pure module */
