'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Banner$NimbusWeb = require("../components/Banner.bs.js");
var Button$NimbusWeb = require("../components/Button.bs.js");
var Context$NimbusWeb = require("./Context.bs.js");
var Icon_Cancel$NimbusWeb = require("../icons/Icon_Cancel.bs.js");

function defaultValue(param) {
  return /* () */0;
}

var include = Context$NimbusWeb.Make({
      defaultValue: defaultValue
    });

var Provider = include.Provider;

function ErrorContext$Wrap(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return ;
        }));
  var setErrorMessage = match[1];
  var errorMessage = match[0];
  var tmp;
  if (errorMessage !== undefined) {
    var message = errorMessage;
    tmp = message !== "" ? React.createElement(Banner$NimbusWeb.make, {
            variant: /* Error */2,
            position: /* Bottom */Block.__(1, ["0"]),
            children: null
          }, React.createElement(Banner$NimbusWeb.Message.make, {
                text: message
              }), React.createElement(Banner$NimbusWeb.RightAdornment.make, {
                children: React.createElement(Button$NimbusWeb.Icon.make, {
                      onClick: (function (param) {
                          return Curry._1(setErrorMessage, (function (param) {
                                        return ;
                                      }));
                        }),
                      children: React.createElement(Icon_Cancel$NimbusWeb.make, {
                            color: /* White */8
                          })
                    })
              })) : null;
  } else {
    tmp = null;
  }
  return React.createElement(Provider.make, Curry._3(Provider.makeProps, setErrorMessage, null, /* () */0), children, tmp);
}

var Wrap = {
  make: ErrorContext$Wrap
};

var context = include.context;

var defaultValue$1 = include.defaultValue;

exports.context = context;
exports.defaultValue = defaultValue$1;
exports.Provider = Provider;
exports.Wrap = Wrap;
/* include Not a pure module */
