'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Request$NimbusWeb = require("../utils/Request.bs.js");
var CheckBox$NimbusWeb = require("./CheckBox.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");
var AuthContext$NimbusWeb = require("../contexts/AuthContext.bs.js");
var ModeContext$NimbusWeb = require("../contexts/ModeContext.bs.js");
var ErrorContext$NimbusWeb = require("../contexts/ErrorContext.bs.js");
var AdminRequest_FetchList$NimbusWeb = require("../scenes/admin/requests/AdminRequest_FetchList.bs.js");

function CheckListAdmin(Props) {
  var selected = Props.selected;
  var onSelect = Props.onSelect;
  Props.selectAll;
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match[0];
  var match$1 = React.useContext(ModeContext$NimbusWeb.context);
  var mode = match$1[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  var match$2 = React.useState((function () {
          return [];
        }));
  var setAdmins = match$2[1];
  var admins = match$2[0];
  var fetchAdmins = function (param) {
    Request$NimbusWeb.send(AdminRequest_FetchList$NimbusWeb.make(/* () */0), token, setErrorMessage, mode).then((function (json) {
              return Promise.resolve(AdminRequest_FetchList$NimbusWeb.$$Response.normalize(Curry._1(AdminRequest_FetchList$NimbusWeb.$$Response.Decode.exec, json)));
            })).then((function (data) {
            Curry._1(setAdmins, (function (param) {
                    return data;
                  }));
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  React.useEffect((function () {
          fetchAdmins(/* () */0);
          return ;
        }), ([]));
  return React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(Typography$NimbusWeb.make, {
                              value: "Select"
                            })), React.createElement("th", undefined, React.createElement(Typography$NimbusWeb.make, {
                              value: "Name"
                            })), React.createElement("th", undefined, React.createElement(Typography$NimbusWeb.make, {
                              value: "Email"
                            })))), admins.length === 0 ? null : React.createElement("tbody", undefined, Belt_Array.map(admins, (function (a) {
                          return React.createElement("tr", {
                                      key: a._id
                                    }, React.createElement("td", undefined, React.createElement(CheckBox$NimbusWeb.make, {
                                              checked: selected.includes(a._id),
                                              onClickCheck: (function (param) {
                                                  return Curry._1(onSelect, a._id);
                                                })
                                            })), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                                              value: a.name
                                            })), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                                              value: a.email
                                            })));
                        }))));
}

var make = CheckListAdmin;

exports.make = make;
/* react Not a pure module */
