'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Typography$NimbusWeb = require("./Typography.bs.js");

require("./DragAndDrop.css");

function DragAndDrop(Props) {
  Props.className;
  var onDrop = Props.onDrop;
  var children = Props.children;
  var showBorder = Props.showBorder;
  var dropRef = React.useRef(null);
  var dragCounter = React.useRef(0);
  var match = React.useState((function () {
          return false;
        }));
  var setDragging = match[1];
  var dragging = match[0];
  var onDragIn = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    dragCounter.current = dragCounter.current + 1 | 0;
    var items = $$event.dataTransfer.items;
    if (items.length > 0) {
      return Curry._1(setDragging, (function (param) {
                    return true;
                  }));
    } else {
      return 0;
    }
  };
  var onDragStart = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    return $$event.dataTransfer.clearData();
  };
  var onDragOut = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    dragCounter.current = dragCounter.current - 1 | 0;
    if (dragCounter.current <= 0) {
      return Curry._1(setDragging, (function (param) {
                    return false;
                  }));
    } else {
      return 0;
    }
  };
  var onDrag = function ($$event) {
    $$event.preventDefault();
    return $$event.stopPropagation();
  };
  var onDrop$1 = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    Curry._1(setDragging, (function (param) {
            return false;
          }));
    if (Belt_List.length($$event.dataTransfer.files) > 0) {
      Curry._1(onDrop, $$event.dataTransfer.files);
      dragCounter.current = 0;
      return /* () */0;
    } else {
      return 0;
    }
  };
  React.useEffect((function () {
          var div = dropRef.current;
          div.addEventListener("ondragstart", onDragStart);
          div.addEventListener("dragenter", onDragIn);
          div.addEventListener("dragleave", onDragOut);
          div.addEventListener("dragover", onDrag);
          div.addEventListener("drop", onDrop$1);
          return ;
        }), ([]));
  return React.createElement("div", {
              ref: dropRef,
              className: "drag-drop-container"
            }, React.createElement("div", {
                  className: "fill " + ((
                      showBorder ? "drag-drop-border " : ""
                    ) + (
                      dragging ? "drag-drop-dragging" : ""
                    )),
                  style: {
                    zIndex: "9999"
                  }
                }, showBorder ? React.createElement("div", {
                        className: "drag-drop-text-container"
                      }, React.createElement(Typography$NimbusWeb.make, {
                            className: dragging ? "drag-drop-dragging-text" : "",
                            value: "Drag and drop image here"
                          })) : null), children);
}

var make = DragAndDrop;

exports.make = make;
/*  Not a pure module */
