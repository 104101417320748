'use strict';

var React = require("react");

function Icon_Menu(Props) {
  var containerStyle = {
    display: "flex",
    height: "14px",
    width: "20px",
    flexDirection: "column",
    justifyContent: "space-between"
  };
  var barStyle = {
    backgroundColor: "white",
    height: "2px",
    width: "100%"
  };
  return React.createElement("div", {
              style: containerStyle
            }, React.createElement("div", {
                  style: barStyle
                }), React.createElement("div", {
                  style: barStyle
                }), React.createElement("div", {
                  style: barStyle
                }));
}

var make = Icon_Menu;

exports.make = make;
/* react Not a pure module */
