'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var AdminResponse$NimbusWeb = require("./AdminResponse.bs.js");

function make(param) {
  var body = { };
  var query = "\n    {\n      admins {\n        ...AdminFragment\n      }\n    }\n    " + (String(AdminResponse$NimbusWeb.fragment) + "\n  ");
  body["query"] = query;
  return body;
}

function decodeAdmins(json) {
  return Json_decode.array(AdminResponse$NimbusWeb.Decode.decodeAdmin, json);
}

function decodeData(json) {
  return {
          admins: Json_decode.field("admins", decodeAdmins, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeAdmins: decodeAdmins,
  decodeData: decodeData,
  exec: exec
};

function normalize(result) {
  var match = result.data;
  if (match !== undefined) {
    return Belt_Array.map(match.admins, AdminResponse$NimbusWeb.normalize);
  } else {
    return [];
  }
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* No side effect */
