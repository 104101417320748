'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var UniversityResponse$NimbusWeb = require("./UniversityResponse.bs.js");

function make(param) {
  var body = { };
  var query = "\n    {\n      universities {\n        ...UniversityFragment\n      }\n    }\n    " + (String(UniversityResponse$NimbusWeb.fragment) + "\n  ");
  body["query"] = query;
  return body;
}

function decodeUniversities(json) {
  return Json_decode.array(UniversityResponse$NimbusWeb.Decode.decodeUniversity, json);
}

function decodeData(json) {
  return {
          universities: Json_decode.field("universities", decodeUniversities, json)
        };
}

function exec(json) {
  return {
          data: Json_decode.optional((function (param) {
                  return Json_decode.field("data", decodeData, param);
                }), json)
        };
}

var Decode = {
  decodeUniversities: decodeUniversities,
  decodeData: decodeData,
  exec: exec
};

function normalize(result) {
  return Belt_Option.map(result.data, (function (data) {
                return Belt_Array.map(data.universities, UniversityResponse$NimbusWeb.normalize);
              }));
}

var $$Response = {
  Decode: Decode,
  normalize: normalize
};

exports.make = make;
exports.$$Response = $$Response;
/* No side effect */
