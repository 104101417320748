'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Page$NimbusWeb = require("../../components/Page.bs.js");
var Color$NimbusWeb = require("../../utils/Color.bs.js");
var Request$NimbusWeb = require("../../utils/Request.bs.js");
var Section$NimbusWeb = require("../../components/Section.bs.js");
var Editable$NimbusWeb = require("../../components/Editable.bs.js");
var Typography$NimbusWeb = require("../../components/Typography.bs.js");
var AuthContext$NimbusWeb = require("../../contexts/AuthContext.bs.js");
var ModeContext$NimbusWeb = require("../../contexts/ModeContext.bs.js");
var ErrorContext$NimbusWeb = require("../../contexts/ErrorContext.bs.js");
var EditableImage$NimbusWeb = require("../../components/EditableImage.bs.js");
var OrganizationRequest_Update$NimbusWeb = require("./requests/OrganizationRequest_Update.bs.js");
var OrganizationRequest_FetchSingle$NimbusWeb = require("./requests/OrganizationRequest_FetchSingle.bs.js");

require("./OrganizationSingle.css");

function OrganizationSingle(Props) {
  var id = Props.id;
  Props.path;
  var match = React.useContext(AuthContext$NimbusWeb.Token.context);
  var token = match[0];
  var match$1 = React.useContext(ModeContext$NimbusWeb.context);
  var mode = match$1[0];
  var setErrorMessage = React.useContext(ErrorContext$NimbusWeb.context);
  var match$2 = React.useState((function () {
          return ;
        }));
  var setOrganization = match$2[1];
  var organization = match$2[0];
  var makeRequest = function (request, exec, normalize) {
    Request$NimbusWeb.send(request, token, setErrorMessage, mode).then((function (json) {
              return Promise.resolve(Curry._1(normalize, Curry._1(exec, json)));
            })).then((function (data) {
            Curry._1(setOrganization, (function (param) {
                    return data;
                  }));
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var fetchOrganization = function (param) {
    return makeRequest(OrganizationRequest_FetchSingle$NimbusWeb.make(id), OrganizationRequest_FetchSingle$NimbusWeb.$$Response.Decode.exec, OrganizationRequest_FetchSingle$NimbusWeb.$$Response.normalize);
  };
  var update = function (arg, type_) {
    var params = { };
    params["organization"] = id;
    switch (type_) {
      case /* Name */0 :
          params["name"] = arg;
          makeRequest(Curry._1(OrganizationRequest_Update$NimbusWeb.Name.make, params), OrganizationRequest_Update$NimbusWeb.Name.$$Response.Decode.exec, OrganizationRequest_Update$NimbusWeb.Name.$$Response.normalize);
          break;
      case /* ShortName */1 :
          params["shortName"] = arg;
          makeRequest(Curry._1(OrganizationRequest_Update$NimbusWeb.ShortName.make, params), OrganizationRequest_Update$NimbusWeb.ShortName.$$Response.Decode.exec, OrganizationRequest_Update$NimbusWeb.ShortName.$$Response.normalize);
          break;
      case /* Logo */2 :
          params["logo"] = arg;
          makeRequest(Curry._1(OrganizationRequest_Update$NimbusWeb.Logo.make, params), OrganizationRequest_Update$NimbusWeb.Logo.$$Response.Decode.exec, OrganizationRequest_Update$NimbusWeb.Logo.$$Response.normalize);
          break;
      case /* LogoHeader */3 :
          params["logoHeader"] = arg;
          makeRequest(Curry._1(OrganizationRequest_Update$NimbusWeb.LogoHeader.make, params), OrganizationRequest_Update$NimbusWeb.LogoHeader.$$Response.Decode.exec, OrganizationRequest_Update$NimbusWeb.LogoHeader.$$Response.normalize);
          break;
      
    }
    return /* () */0;
  };
  React.useEffect((function () {
          fetchOrganization(/* () */0);
          return ;
        }), ([]));
  var onSaveImage = function (type_, fileOption) {
    if (fileOption !== undefined && organization !== undefined) {
      var org = organization;
      var endpoint = type_ !== 2 ? (
          type_ >= 3 ? "/images/organization_logoheader?name=" + org.name : ""
        ) : "/images/organization_logo?name=" + org.name;
      Request$NimbusWeb.sendFile(Caml_option.valFromOption(fileOption), endpoint, token, setErrorMessage, mode).then((function (jsonOption) {
                if (jsonOption !== undefined) {
                  var data = Request$NimbusWeb.Decode.decodeSendFileResponse(Caml_option.valFromOption(jsonOption));
                  return Promise.resolve(data.uri);
                } else {
                  return Promise.resolve(undefined);
                }
              })).then((function (uriOption) {
              if (uriOption !== undefined) {
                var uri = uriOption;
                if (type_ !== 2 && type_ >= 3) {
                  update(uri, /* LogoHeader */3);
                } else {
                  update(uri, /* Logo */2);
                }
                return Promise.resolve(/* () */0);
              } else {
                alert("uri not found");
                return Promise.resolve(/* () */0);
              }
            }));
      return /* () */0;
    } else {
      alert("No organization or file selected");
      return /* () */0;
    }
  };
  var colorSquare = function (color) {
    return React.createElement("div", {
                style: {
                  backgroundColor: color,
                  height: "20px",
                  width: "20px"
                }
              });
  };
  var tmp;
  if (organization !== undefined) {
    var org = organization;
    var match$3 = org.logo;
    var tmp$1;
    if (match$3 !== undefined) {
      var logo = match$3;
      tmp$1 = logo !== "" ? React.createElement(EditableImage$NimbusWeb.make, {
              className: "org-single-logo",
              src: logo,
              alt: "logo",
              onSave: (function (file) {
                  return onSaveImage(/* Logo */2, file);
                })
            }) : null;
    } else {
      tmp$1 = null;
    }
    var match$4 = org.logoHeader;
    var tmp$2;
    if (match$4 !== undefined) {
      var logoHeader = match$4;
      tmp$2 = logoHeader !== "" ? React.createElement(EditableImage$NimbusWeb.make, {
              className: "org-single-logo-header",
              src: logoHeader,
              alt: "logo_header",
              onSave: (function (file) {
                  return onSaveImage(/* LogoHeader */3, file);
                })
            }) : null;
    } else {
      tmp$2 = null;
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1, tmp$2);
  } else {
    tmp = null;
  }
  var tmp$3;
  if (organization !== undefined) {
    var match$5 = organization.colors;
    if (match$5 !== undefined) {
      var colors = match$5;
      var format = function (rgbString) {
        var match = Color$NimbusWeb.fromRgbString(rgbString);
        if (match !== undefined) {
          return Color$NimbusWeb.toString(match);
        } else {
          return "";
        }
      };
      var studentLight = format(colors.student.light);
      var studentMedium = format(colors.student.medium);
      var studentDark = format(colors.student.dark);
      var tutorLight = format(colors.tutor.light);
      var tutorMedium = format(colors.tutor.medium);
      var tutorDark = format(colors.tutor.dark);
      tmp$3 = React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", undefined), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: "Student"
                          })), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: "Tutor"
                          })))), React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: "Light"
                          })), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: studentLight
                          }), studentLight !== "" ? colorSquare(colors.student.light) : null), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: tutorLight
                          }), tutorLight !== "" ? colorSquare(colors.tutor.light) : null)), React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: "Medium"
                          })), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: studentMedium
                          }), studentMedium !== "" ? colorSquare(colors.student.medium) : null), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: tutorMedium
                          }), tutorMedium !== "" ? colorSquare(colors.tutor.medium) : null)), React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: "Dark"
                          })), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: studentDark
                          }), studentDark !== "" ? colorSquare(colors.student.dark) : null), React.createElement("td", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: tutorDark
                          }), tutorDark !== "" ? colorSquare(colors.tutor.dark) : null))));
    } else {
      tmp$3 = React.createElement(Typography$NimbusWeb.make, {
            value: "default"
          });
    }
  } else {
    tmp$3 = React.createElement(Typography$NimbusWeb.make, {
          value: "default"
        });
  }
  return React.createElement(Page$NimbusWeb.make, {
              children: React.createElement(Section$NimbusWeb.make, {
                    className: "org-single-section",
                    children: null
                  }, tmp, React.createElement(Editable$NimbusWeb.Typography.make, {
                        value: organization !== undefined ? organization.name : "Name",
                        onSave: (function (name) {
                            return update(name, /* Name */0);
                          })
                      }), React.createElement(Editable$NimbusWeb.Typography.make, {
                        value: organization !== undefined ? organization.shortName : "Short name",
                        onSave: (function (shortName) {
                            return update(shortName, /* ShortName */1);
                          })
                      }), React.createElement(Typography$NimbusWeb.make, {
                        value: id
                      }), React.createElement("div", undefined, React.createElement(Typography$NimbusWeb.make, {
                            value: "Color scheme:"
                          }), tmp$3))
            });
}

var make = OrganizationSingle;

exports.make = make;
/*  Not a pure module */
