'use strict';

var React = require("react");
var OrganizationList$NimbusWeb = require("./OrganizationList.bs.js");
var OrganizationCreate$NimbusWeb = require("./OrganizationCreate.bs.js");
var OrganizationSingle$NimbusWeb = require("./OrganizationSingle.bs.js");

function OrganizationController(Props) {
  var path = Props.path;
  if (path) {
    var id = path[0];
    if (id === "create") {
      return React.createElement(OrganizationCreate$NimbusWeb.make, {
                  path: path[1]
                });
    } else {
      return React.createElement(OrganizationSingle$NimbusWeb.make, {
                  id: id,
                  path: path[1]
                });
    }
  } else {
    return React.createElement(OrganizationList$NimbusWeb.make, { });
  }
}

var make = OrganizationController;

exports.make = make;
/* react Not a pure module */
