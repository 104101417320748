'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactDom = require("react-dom");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");
var Context$NimbusWeb = require("../contexts/Context.bs.js");
var Typography$NimbusWeb = require("./Typography.bs.js");

require("./Banner.css");

var root = document.getElementById("root");

var BannerContext = Context$NimbusWeb.Make({
      defaultValue: /* Default */0
    });

function Banner$LeftAdornment(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "banner-left-adornment-container"
            }, children);
}

var LeftAdornment = {
  make: Banner$LeftAdornment
};

function Banner$RightAdornment(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "banner-adornment-container"
            }, children);
}

var RightAdornment = {
  make: Banner$RightAdornment
};

function Banner$Message(Props) {
  var text = Props.text;
  var variant = React.useContext(BannerContext.context);
  var textColor = variant !== 0 ? /* White */8 : /* Black */9;
  return React.createElement("div", {
              className: "banner-message-container"
            }, React.createElement("div", {
                  className: "banner-message-text-container"
                }, React.createElement(Typography$NimbusWeb.make, {
                      color: textColor,
                      value: text
                    })));
}

var Message = {
  make: Banner$Message
};

function Banner(Props) {
  var variantOpt = Props.variant;
  var positionOpt = Props.position;
  var children = Props.children;
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var position = positionOpt !== undefined ? positionOpt : /* Top */Block.__(0, ["50px"]);
  var bgColor;
  switch (variant) {
    case /* Default */0 :
        bgColor = Theme$NimbusWeb.getColor(/* White */8);
        break;
    case /* Success */1 :
        bgColor = Theme$NimbusWeb.getColor(/* Success */3);
        break;
    case /* Error */2 :
        bgColor = Theme$NimbusWeb.getColor(/* Primary */0);
        break;
    
  }
  var style;
  style = position.tag ? ({
        backgroundColor: bgColor,
        bottom: position[0]
      }) : ({
        backgroundColor: bgColor,
        top: position[0]
      });
  return ReactDom.createPortal(React.createElement(BannerContext.Provider.make, Curry._3(BannerContext.Provider.makeProps, variant, React.createElement("div", {
                          className: "banner-container",
                          style: style
                        }, children), /* () */0)), root);
}

var make = Banner;

exports.root = root;
exports.BannerContext = BannerContext;
exports.LeftAdornment = LeftAdornment;
exports.RightAdornment = RightAdornment;
exports.Message = Message;
exports.make = make;
/*  Not a pure module */
