'use strict';

var React = require("react");
var Theme$NimbusWeb = require("../utils/Theme.bs.js");

require("./Loader.css");

function Loader$Circular(Props) {
  var classNameOpt = Props.className;
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var size = sizeOpt !== undefined ? sizeOpt : /* Medium */1;
  var color = colorOpt !== undefined ? colorOpt : /* Primary */0;
  var s;
  switch (size) {
    case /* Small */0 :
        s = "40px";
        break;
    case /* Medium */1 :
        s = "50px";
        break;
    case /* Large */2 :
        s = "60px";
        break;
    
  }
  var c = Theme$NimbusWeb.getColor(color);
  return React.createElement("div", {
              className: "loader-circular " + className,
              style: {
                borderTop: "4px solid " + c,
                height: s,
                width: s
              }
            });
}

var Circular = {
  make: Loader$Circular
};

exports.Circular = Circular;
/*  Not a pure module */
